import i18next from "i18next";
import React, { useCallback, useMemo, useState } from "react";

import css from "./changePassword.module.scss";
import { Button } from "components";
import { useDispatch } from "react-redux";
import { setAppModal } from "redux/slices/appSlice";
import { MODALS } from "common";
import authService from "services/auth.service";

export default function InviteMember() {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [error, setError] = useState({
    show: false,
    message: "",
  });

  const onCancel = useCallback(() => {
    dispatch(setAppModal(MODALS.NONE));
  }, [dispatch]);

  const handleOnChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const cancelStyle = useMemo(() => {
    return {
      width: "145px",
      alignItem: "center",
      color: "#344054",
      backgroundColor: "#ffffff",
      shadowColor: "rgba(16, 24, 40, 0.05)",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowRadius: 2,
      shadowOpacity: 1,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#d0d5dd",
      padding: "3px 0",
    };
  }, []);

  const sendlStyle = useMemo(() => {
    return {
      width: "145px",
      alignItem: "center",
      shadowColor: "rgba(16, 24, 40, 0.05)",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowRadius: 2,
      shadowOpacity: 1,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#d0d5dd",
      padding: "3px 0",
    };
  }, []);

  const onSubmit = async () => {
    try {
      const response = await authService.updatePassword(values);
      if (response.success) dispatch(setAppModal(MODALS.NONE));
    } catch (e) {
      const message = e?.response?.data?.message || e.message || e.toString();
      setError({ show: true, message: message });
    }
  };

  return (
    <div className={css.container}>
      <div className={css.title}>{i18next.t("labels.change_password")}</div>
      <div className={css.body}>
        <div className={css.inputWrapper}>
          <label className={css.label}>
            {i18next.t("labels.exist_password")}
          </label>
          <input
            type="password"
            className={css.inputFiled}
            name="password"
            onChange={handleOnChange}
          />
        </div>
        <div className={css.inputWrapper}>
          <label className={css.label}>
            {i18next.t("labels.new_password")}
          </label>
          <input
            type="password"
            className={css.inputFiled}
            name="newPassword"
            onChange={handleOnChange}
          />
        </div>
        <div className={css.inputWrapper}>
          <label className={css.label}>
            {i18next.t("labels.confirm_new_password")}
          </label>
          <input
            type="password"
            className={css.inputFiled}
            name="confirmNewPassword"
            onChange={handleOnChange}
          />
        </div>
        {error.show && <div className={css.error}>{error.message}</div>}
        <div className={css.buttonsContainer}>
          <Button title={"Cancel"} style={cancelStyle} onPress={onCancel} />
          <Button title={"Send"} style={sendlStyle} onPress={onSubmit} />
        </div>
      </div>
    </div>
  );
}
