import React from 'react'
import "./loader.scss"
import LoaderSVG from '../../resources/icons/loader.svg'

export default function Loader() {
  return (
    <div className='loader-container'>
      <img src={LoaderSVG}></img>
    </div>
  )
}
