import React, { useEffect, useMemo, useState } from 'react'
import './progressBar.scss'

export default function ProgressBar({ progress, width, height, backgroundColor = "#80BBDD", progressColor = "#FFFFFF" }) {
  const styleContainer = useMemo(() => {
    return {
      width: typeof(width) === "string" ? width  : `${width}px`,
      height: typeof(height) === "string" ? height  : `${height}px`,
      borderRadius: height / 2 + 'px',
      backgroundColor: backgroundColor,
    }
  }, [width, height, backgroundColor]);

  const [styleInner, setStyleInner] = useState( {
    width: '0',
    borderRadius: height / 2 + 'px',
    backgroundColor: progressColor,
  });

  useEffect(() => {
    setStyleInner({
      width: (Math.min(100, Math.floor(progress * 100))) + '%',
      borderRadius: height / 2 + 'px',
      backgroundColor: progressColor,
    });
  }, [width, height, progress, setStyleInner, progressColor])
  

  return (
    <div className='progress-bar' style={styleContainer}>
      <div className='inner' style={styleInner}/>
    </div>
  )
}
