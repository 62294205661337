import { fetchResourcesCategories, selectResourcesCategories } from 'redux/slices/resourcesSlice'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import React, { Fragment, useEffect } from 'react'
import { PageBody, PageHeader } from 'components'
import './resources.scss'

function Category({ title, files, coverImage }) {
  return <div className='resources-category'>
    <div className='title'>{title}</div>
    <div className='files-container'>
      {files?.map?.((file, index) => {
        return <div key={`resources-category-content-${index}`} className='file-container'>
          <div className='background'>
            <img src={coverImage} className='icon' alt=""/>
          </div>
          <div className='title'>{file.name}</div>
          <div className='line' />
          <a href={file.contentUrl} className='download'>{i18next.t("labels.download")}</a>
        </div>
      })}
    </div>
  </div>;
}


export default function ResourcesPage() {
  const dispatch = useDispatch();
  const categories = useSelector(selectResourcesCategories)
  
  useEffect(() => {
    dispatch(fetchResourcesCategories())
  }, [dispatch])
  
  return (
    <div className="resources-container">
      <PageHeader title={i18next.t("labels.resources")} />
      <PageBody padding={'32px'}>
        <div className='resources'>
        {categories?.map?.((item, index) =>
          <Fragment key={`resources-category-${index}`}>
            <Category title={item?.title} coverImage={item.coverImageUrl} files={item?.content} />
            <div className='line' />
          </Fragment>)}
          </div>
      </PageBody>
    </div>
  )
}
