import React, { useCallback, useMemo } from 'react'
import './navigationItem.scss'
import homeImage from 'resources/icons/home.svg'
import usersImage from 'resources/icons/users.svg'
import resourcesImage from 'resources/icons/resources.svg'
import supportImage from 'resources/icons/support.svg'
import settingsImage from 'resources/icons/settings.svg'
import i18next from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectAppActiveItem, setAppActiveItem } from 'redux/slices/appSlice'
import { NAVIGATION_ITEM } from 'common'
import { Link, useNavigate } from 'react-router-dom'

export default function NavigationItem({ type }) {
  const imageSrc = useMemo(() => {
    switch (type) {
      case NAVIGATION_ITEM.HOME:
        return homeImage;
      case NAVIGATION_ITEM.USERS:
        return usersImage;
      case NAVIGATION_ITEM.RESOURCES:
        return resourcesImage;
      case NAVIGATION_ITEM.SUPPORT:
        return supportImage;
      case NAVIGATION_ITEM.SETTINGS:
        return settingsImage;
      default:
        return null;
    }
  }, [type]);

  const title = useMemo(() => {
    switch (type) {
      case NAVIGATION_ITEM.HOME:
        return i18next.t("labels.dashboard");
      case NAVIGATION_ITEM.USERS:
        return i18next.t("labels.users");
      case NAVIGATION_ITEM.RESOURCES:
        return i18next.t("labels.resources");
      case NAVIGATION_ITEM.SUPPORT:
        return i18next.t("labels.support");
      case NAVIGATION_ITEM.SETTINGS:
        return i18next.t("labels.settings");
      default:
        return "";
    }
  }, [type]);

  const selectedActiveItem = useSelector(selectAppActiveItem);
  const dispatch = useDispatch(selectAppActiveItem);

  const onClick = useCallback(
    () => {
      dispatch(setAppActiveItem(type));
    },
    [type],
  )

  return (
    <Link to={`/${(type || '').toLowerCase()}`} style={styles.link}>
      <div className={`navigationItem ${type == selectedActiveItem ? 'selected' : ''}`} onClick={onClick}>
        <div className='imageContainer'>
          <img src={imageSrc} className='image'></img>
        </div>
        <div className='text'>{title}</div>
      </div>
    </Link>
  )
}

const styles = {
  link: {
    textDecoration: 'none',
  }

}