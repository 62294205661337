import { store } from "redux/store";
import axios from "axios";
import { API_URL } from "common/constants";
import i18next from "i18next";
import { selectAccountAccessToken } from "redux/slices/accountSlice";
import { resources } from "./resources/locales";
import setupInterceptors from "./services/apiInterceptors";

export default class AppLogic {
  static #isInited = false;
  static #axiosFetcher = null;
  static getAxios = () => {
    if (AppLogic.#axiosFetcher) {
      // const accessToken = selectAccountAccessToken(store.getState());
      // AppLogic.#axiosFetcher.defaults.headers.common["authorization"] = accessToken
      return AppLogic.#axiosFetcher;
    }
    return null;
  }
  static Init = async () => {
    if (!this.#isInited) {
      this.#isInited = true;
      await i18next.init({
        lng: "en",
        debug: false,
        compatibilityJSON: "v3",
        resources,
      });

      const accessToken = selectAccountAccessToken(store.getState());
      AppLogic.#axiosFetcher = axios.create({
        baseURL: API_URL,
        headers: {
          authorization: accessToken
        },
      });

      setupInterceptors();
    }
  }
}