import AppLogic from "AppLogic";
import { API_URL } from "common/constants";

const postUsersEmails = async (body) => {
  return await AppLogic.getAxios().post(API_URL + "organization-profile/add-users", body,
    { headers: { "Content-Type": "multipart/form-data" } });
};

const getUsersList = ({ page = 0, size = 0, search = null, order = null, sortedBy = null }) => {
  return AppLogic.getAxios()
    .get(
      `organization-profile/get-users?page=${page}&size=${size}${search ? `&search=${search}` : ""}${sortedBy ? `&sortedBy=${sortedBy}` : ""}${order ? `&order=${order}` : ""}`
    )
    .then((response) => {
      return response.data;
    });
};

const deleteMember = (email) => {
  return AppLogic.getAxios()
    .delete(`users/remove`, {
      data: { email },
    })
    .then((response) => {
      return response.data;
    });
};

const updateMemberRole = ({ email, role }) => {
  return AppLogic.getAxios()
    .put(
      `users/update-role`,
      { email, role },
    )
    .then((response) => {
      return response.data;
    });
};

const removeUserFromOrginization = ({ email, phoneNumber }) => {
  return AppLogic.getAxios()
    .delete(`organization-profile/remove-user`, {
      data: { email, phoneNumber },
    })
    .then((response) => {
      return response.data;
    });
};

const usersService = {
  postUsersEmails,
  getUsersList,
  deleteMember,
  updateMemberRole,
  removeUserFromOrginization,
};

export default usersService;
