import i18next from "i18next";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ActiveUsersCard,
  MinutesChart,
  DailyActivityChart,
  HourlyActivityChart,
  PageBody,
  PageHeader,
  StreamTitlesChart,
  MoodChart,
  FeelingsCard,
  ObjectivesCard,
} from "components";
import { selectAccountName } from "redux/slices/accountSlice";
import "./dashboard.scss";
import {
  selectActiveUsers,
  selectTotalUsers,
  selectInScopeCountries,
  selectExpiredUsers,
  selectRemainingSubscriptions,
} from "redux/slices/orginizationSlice";
import {
  fetchDashboardMinPerListeners,
  fetchDashboardMoods,
  fetchDashboardObjectives,
  fetchDashboardTitles,
  selectDashboardLastFetchTime,
  selectDashboardMinPerListener,
  selectDashboardMoods,
  selectDashboardObjectives,
  selectDashboardTitles,
  setDashboardTargetCountry,
  selectDashboardTargetCountry,
  selectDashboardDailyActivity,
  selectDashboardHourlyActivity,
  fetchDashboardDailyActivity,
  fetchDashboardHourlyActivity,
  selectDashboardMinutesChartOption,
  selectDashboardActivityChartOption,
  selectDashboardMoodChartOption,
} from "redux/slices/dashboardSlice";
import { getOrginizationinfo } from "redux/slices/orginizationSlice";
import { store } from "redux/store";

const MIN_FETCH_DURATION = 1000 * 60; // 1-min
export default function DashboardPage() {
  const name = useSelector(selectAccountName);

  const allUsers = useSelector(selectTotalUsers);
  const remainingSubscriptions = useSelector(selectRemainingSubscriptions);
  const targetCountry = useSelector(selectDashboardTargetCountry);
  const activeUsers = useSelector(selectActiveUsers);
  const expiredUsers = useSelector(selectExpiredUsers);
  const inScopeCountries = useSelector(selectInScopeCountries);

  const dispatch = useDispatch();
  const titles = useSelector(selectDashboardTitles);
  const minPerListener = useSelector(selectDashboardMinPerListener);
  const moods = useSelector(selectDashboardMoods);
  const dailyActivity = useSelector(selectDashboardDailyActivity);
  const hourlyActivity = useSelector(selectDashboardHourlyActivity);
  const objectives = useSelector(selectDashboardObjectives);

  const minutesChartOption = useSelector(selectDashboardMinutesChartOption);
  const activityChartOption = useSelector(selectDashboardActivityChartOption);
  const moodChartOption = useSelector(selectDashboardMoodChartOption);

  const todayMood = useMemo(() => {
    let happy = moods[moods?.length - 1 || 0]?.happy || 0;
    let normal = moods[moods?.length - 1 || 0]?.normal || 0;
    let sad = moods[moods?.length - 1 || 0]?.sad || 0;
    let total = (happy + normal + sad) / 1;
    total = total || 1;
    happy = Math.round((happy / total) * 100);
    normal = Math.round((normal / total) * 100);
    sad = sad ? 100 - happy - normal : 0;
    return { happy, normal, sad };
  }, [moods]);

  useEffect(() => {
    const lastFetch = selectDashboardLastFetchTime(store.getState());
    const lastFetchDuration = Date.now() - lastFetch;
    if (!lastFetch || lastFetchDuration > MIN_FETCH_DURATION || targetCountry) {
      dispatch(fetchDashboardTitles(targetCountry));
      dispatch(fetchDashboardMinPerListeners({ targetCountry: targetCountry, minutesChartOption: minutesChartOption }));
      dispatch(fetchDashboardMoods({ targetCountry: targetCountry, moodChartOption: moodChartOption }));
      dispatch(fetchDashboardDailyActivity({ targetCountry: targetCountry, activityChartOption: activityChartOption }));
      dispatch(fetchDashboardHourlyActivity(targetCountry));
      dispatch(fetchDashboardObjectives(targetCountry));
      dispatch(getOrginizationinfo(targetCountry));
    }
  }, [dispatch, targetCountry]);

  useEffect(() => {
    dispatch(fetchDashboardMinPerListeners({ targetCountry: targetCountry, minutesChartOption: minutesChartOption }));
  }, [minutesChartOption]);

  useEffect(() => {
    dispatch(fetchDashboardDailyActivity({ targetCountry: targetCountry, activityChartOption: activityChartOption }));
  }, [activityChartOption]);

  useEffect(() => {
    dispatch(fetchDashboardMoods({ targetCountry: targetCountry, moodChartOption: moodChartOption }));
  }, [moodChartOption]);
 
  const handleCountryChange = (country) => {
    dispatch(setDashboardTargetCountry(country));
  };

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <PageHeader
        title={`${i18next.t("labels.welcome_back")}${name}`}
        description={i18next.t("labels.track")}
        borderless
      />
      <div className="outer-container">
        <div className="button-container">
          {inScopeCountries?.length > 1 &&
            inScopeCountries.map(({ key, title }) => (
              <button
                key={key + title}
                onClick={() => handleCountryChange(key)}
                disabled={targetCountry === key}
              >
                {title}
              </button>
            ))}
        </div>
      </div>
      <PageBody padding={"32px"}>
        <div className="cards">
          <ActiveUsersCard
            allUsers={allUsers}
            expiredUsers={expiredUsers}
            activeUsers={activeUsers}
            remainingSubscriptions={remainingSubscriptions}
          />
          <FeelingsCard
            happy={todayMood.happy}
            normal={todayMood.normal}
            sad={todayMood.sad}
          />
          <ObjectivesCard objectives={objectives} />
        </div>
        <div className="cards margin">
          <MinutesChart minPerListener={minPerListener} />
          <StreamTitlesChart titles={titles} />
        </div>
        <div className="cards margin">
          <DailyActivityChart activity={dailyActivity} />
          <HourlyActivityChart activity={hourlyActivity} />
        </div>
        <div className="cards margin">
          <MoodChart moods={moods} />
        </div>
      </PageBody>
    </div>
  );
}
