import React from "react";
import "./pageHeader.scss";
import {
  addInnerBodyPadding,
  addMoodColumnMargin,
  addShadow,
  appendHideShowClasses,
  exportAsImage,
  exportButtonDisplayBlock,
  exportButtonDisplayNone,
  removeHideShowClasses,
  removeInnerBodyPadding,
  removeMoodColumnMargin,
  removeShadow,
  chartOptionsDisplayBlock,
  chartOptionsDisplayNone,
} from "utils/exportAsImage";
import i18next from "i18next";
import { useLocation } from "react-router-dom";
import { selectPlanExpiry } from "redux/slices/orginizationSlice";
import { useSelector } from "react-redux";

export default function PageHeader({ title, description, borderless }) {
  const location = useLocation();
  const planExpiry = useSelector(selectPlanExpiry);
  const dashboardElement = document.getElementsByClassName("inner-body")[0];
  return (
    <div className={`page-header ${borderless ? "borderless" : ""}`}>
      <div className="header-centered text align-end">
        <div>{title}</div>
        {location.pathname === "/" || location.pathname === "/home" ? (
          <button
            style={{ marginRight: "10rem" }}
            className="button"
            onClick={async () => {
              exportButtonDisplayNone();
              chartOptionsDisplayNone();
              addInnerBodyPadding();
              removeShadow();
              removeHideShowClasses(dashboardElement);
              addMoodColumnMargin(dashboardElement);
              await exportAsImage(dashboardElement, "HR dashboard");
              appendHideShowClasses(dashboardElement);
              removeMoodColumnMargin(dashboardElement);
              removeInnerBodyPadding();
              exportButtonDisplayBlock();
              chartOptionsDisplayBlock();
              addShadow();
            }}
          >
            {i18next.t("buttons.export_dashboard")}
          </button>
        ) : null}
      </div>
      {description && (
        <div className=" header-centered text-small">{description}</div>
      )}
      {new Date(planExpiry) < new Date() ? (
        <div className=" header-centered text-red">
          {i18next.t("labels.expired_license")}
        </div>
      ) : null}
    </div>
  );
}
