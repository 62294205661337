import { store } from "redux/store";
import { setAccountAccessToken, setAccountRefreshToken } from "redux/slices/accountSlice";
import AppLogic from "AppLogic";

const login = (email, password) => {
  return AppLogic.getAxios()
    .post("auth/signin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        store.dispatch(setAccountAccessToken(response.data?.accessToken));
      }
      if (response.data.refreshToken) {
        store.dispatch(setAccountRefreshToken(response.data?.refreshToken));
      }

      return response.data;
    });
};

const update = (body) => {
  return AppLogic.getAxios().put("users/update", body).then((response) => {
    if (response.data) {
      if (response.data?.accessToken) {
        store.dispatch(setAccountAccessToken(response.data?.accessToken));
      }
      if (response.data?.refreshToken) {
        store.dispatch(setAccountRefreshToken(response.data?.refreshToken));
      }
    }
    return response.data;
  });
};

const logout = () => {
  localStorage.removeItem("user");
  //TODO: Yaaqba do request to BE
};

const updatePassword = (body) => {
  return AppLogic.getAxios().post("auth/update-password", body).then((response) => {
    return response.data;
  });
};

const authService = {
  // register,
  login,
  logout,
  update,
  updatePassword,
};

export default authService;
