import html2canvas from "html2canvas";

export const exportAsImage = async (element, imageFileName) => {
  console.log(
    "🚀 ~ file: exportAsImage.js:4 ~ exportAsImage ~ element:",
    element
  );
  const canvas = await html2canvas(element);
  const image = canvas.toDataURL("image/png", 1.0);
  downloadImage(image, imageFileName);
  return { image, imageFileName };
};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

export const removeHideShowClasses = (element) => {
  element.querySelectorAll(".mood-column.hide-show").forEach((el) => {
    el.classList.remove("hide-show");
  });
};

export const appendHideShowClasses = (element) => {
  element.querySelectorAll(".mood-column").forEach((el) => {
    el.classList.add("hide-show");
  });
};

export const addMoodColumnMargin = (element) => {
  element.querySelectorAll(".mood-column-text").forEach((el) => {
    el.classList.add("mt-25px");
  });
  element.querySelectorAll(".mood-column-label").forEach((el) => {
    el.classList.add("mt-10px");
  });
};

export const removeMoodColumnMargin = async (element) => {
  element.querySelectorAll(".mood-column-text").forEach((el) => {
    el.classList.remove("mt-25px");
  });
  element.querySelectorAll(".mood-column-label").forEach((el) => {
    el.classList.add("mt-10px");
  });
};

export const exportButtonDisplayNone = () => {
  document.querySelectorAll(".export-btn").forEach((el) => {
    el.classList.add("export-btn-none");
  });
};

export const exportButtonDisplayBlock = () => {
  document.querySelectorAll(".export-btn").forEach((el) => {
    el.classList.remove("export-btn-none");
  });
};

export const chartOptionsDisplayNone = () => {
  document.querySelectorAll(".chart-options-container").forEach((el) => {
    el.classList.add("chart-options-container-none");
  });
};

export const chartOptionsDisplayBlock = () => {
  document.querySelectorAll(".chart-options-container").forEach((el) => {
    el.classList.remove("chart-options-container-none");
  });
};

export const addInnerBodyPadding = () => {
  document.getElementById("inner-body").classList.add("padding-25px");
};

export const removeInnerBodyPadding = () => {
  document.getElementById("inner-body").classList.remove("padding-25px");
};

export const removeShadow = () => {
  document.querySelectorAll(".card").forEach((el) => {
    el.classList.add("no-shadow-no-animation");
  });
};

export const addShadow = () => {
  document.querySelectorAll(".card").forEach((el) => {
    el.classList.remove("no-shadow-no-animation");
  });
};
