import i18next from "i18next";
import React, { useMemo, useState } from "react";
import { Button, PageBody, PageHeader } from "components";
import "./support.scss";
import { useSelector } from "react-redux";
import { accountDetails } from "redux/slices/accountSlice";
import AppLogic from "AppLogic";

export default function SupportPage() {
  const userData = useSelector(accountDetails);
  const [values, setValues] = useState({
    name: userData.firstName || "",
    email: userData.email || "",
    type: "Tech Support",
    message: "",
  });
  const [showNotification, setShowNotification] = useState({
    success: false,
    show: false,
  });
  const buttonStyle = useMemo(() => {
    return {
      width: "128px",
      paddingTop: "4px",
      paddingBottom: "4px",
      alignSelf: "flex-end",
      marginTop: "8px",
    };
  }, []);

  const resetData = (e) => {
    setValues({
      name: userData.firstName || "",
      email: userData.email || "",
      type: "Tech Support",
      message: "",
    });
    setShowNotification({
      success: false,
      show: false,
    });
  };
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const sendRequest = await AppLogic.getAxios().post("support", values);

    if (sendRequest.data?.accepted?.length > 0) {
      setShowNotification({
        success: true,
        show: true,
      });
      setTimeout(resetData, 5000);
    }
  };
  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <PageHeader title={i18next.t("labels.support")} />
      <PageBody padding={"32px"}>
        <div className="contact-us">
          <div className="title">{i18next.t("labels.contact_us")}</div>
          <div className="sub-title">{i18next.t("paragraphs.contact_us")}</div>
          <div className="line" />
          <form className="contact-us-form">
            <div className="section-container">
              <div className="section">
                <div className="form-title">{i18next.t("labels.name")}</div>
                <input
                  onChange={onChange}
                  name="name"
                  placeholder={i18next.t("labels.your_name")}
                  value={values.name}
                />
              </div>
              <div className="section">
                <div className="form-title">{i18next.t("labels.email")}</div>
                <input
                  onChange={onChange}
                  name="email"
                  placeholder={i18next.t("labels.email_example")}
                  value={values.email}
                  disabled
                />
              </div>
            </div>
            <div className="section-container">
              <div className="section">
                <div className="form-title">
                  {i18next.t("labels.type_of_enquiry")}
                </div>
                <select onChange={onChange} name="type" value={values.type}>
                  <option value={"Tech Support"}>Tech Support</option>
                  <option value={"Suggestions"}>Suggestions</option>
                  <option value={"Others"}>Others</option>
                </select>
              </div>
              <div className="section" />
            </div>
            <div className="section-container">
              <div className="section">
                <div className="form-title">
                  {i18next.t("paragraphs.how_can_we_help")}
                </div>
                <textarea
                  placeholder={i18next.t("paragraphs.tell_us")}
                  rows="6"
                  onChange={onChange}
                  name="message"
                  value={values.message}
                />
              </div>
            </div>
            <Button
              onPress={onSubmit}
              title={i18next.t("buttons.send")}
              style={buttonStyle}
            />
          </form>
          {showNotification.show && showNotification.success && (
            <div className="notficiation-success">
              {i18next.t("notification.support_email_success")}
            </div>
          )}
        </div>
      </PageBody>
    </div>
  );
}
