import i18next from "i18next";
import React, { useEffect, useState } from "react";
import Utils from "common/utils";
import ProgressBar from "../progressBar";
import PlanIcon from "../../resources/icons/plan.svg";

import "./cards.scss";
import AnimatedCounter from "components/animatedCounter";

export default function CompanyPlanCard({
  allUsers,
  activeUsers,
  expirationDate,
  className = "",
}) {
  const expirationDateString = new Date(expirationDate);
  return (
    <div className={`card white noGap profile ${className} plan`}>
      <div className="row">
        <img className="icon" src={PlanIcon} alt="plan" />
        <div className="colum">
          <div className="planDetails">
            {Utils.numberWithCommas(allUsers)} plan
          </div>
          {/* // TODO: expiration date must be reformatted depend on how we will get it from BE 
                  for demo we will use it staticly here for now */}

          <div className="expire">
            Expires: {expirationDateString.getUTCDate()}/
            {expirationDateString.getUTCMonth() + 1}/
            {expirationDateString.getUTCFullYear()}
          </div>
        </div>
      </div>
      <div className="colum">
        <div className="numbers count">
          <AnimatedCounter finish={activeUsers} useComma />
          &nbsp;
          {`${i18next.t("labels.of")} ${Utils.numberWithCommas(
            allUsers
          )} ${i18next.t("labels.users_small")}`}
        </div>
        <div className="progress">
          <ProgressBar
            width={"100%"}
            height={8}
            progress={activeUsers / allUsers}
            progressColor={"#5ac2b4"}
            backgroundColor={"#d4efeb"}
          />
        </div>
      </div>
    </div>
  );
}
