import AppLogic from "AppLogic";
import { API_URL } from "common/constants";

const getResourcesCategories = async () => {
	return (
		await AppLogic.getAxios().get(
			API_URL +
				"resources/categories?filters=%7B%7D&order=ASC&perPage=15&sort=id&start=0"
		)
	).data;
};

const resourcesService = {
	getResourcesCategories,
};

export default resourcesService;
