import React, { useEffect, useMemo, useRef, useState } from "react";
import "./chart.scss";
import i18next from "i18next";
import {
  exportAsImage,
  exportButtonDisplayBlock,
  exportButtonDisplayNone,
  chartOptionsDisplayBlock,
  chartOptionsDisplayNone,
} from "utils/exportAsImage";
import resourcesImage from "resources/icons/resources.svg";
import { CHART_OPTIONS } from "common";
import { selectDashboardActivityChartOption, setDashboardActivityChartOption } from "redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";

const SVG_WIDTH = 400;
const SVG_HEIGHT = 200;

const generatePathPoints = (maxY, maxX, values, limits) => {
  if (limits?.length == 0) return "";
  maxY = (maxY * 5) / 6;
  const steps = values.length;
  const limitMin = limits[0];
  const limitMax = limits[limits.length - 1];
  const limitHeight = limitMax - limitMin;
  const valuesYSVG = values.map((item, index) => {
    return ((limitMax - item) / limitHeight) * maxY;
  });
  const valuesXSVG = values.map((_, index) => {
    return (index / (values.length - 1)) * maxX;
  });

  let path = "";

  for (let i = 0; i < steps; i++) {
    if (i == 0) {
      path += "M";
    } else {
      path += "L";
    }
    path += valuesXSVG[i] + " " + valuesYSVG[i];
  }

  let path2 = `M 0 ${maxY} L ${
    path ? path.substring(1, path.length) : 0
  } L ${maxX} ${maxY} Z`;
  return [path, path2];
};

const generateHorizontal = (maxX, maxY, days) => {
  const values = [];
  for (let i = 0; i < days.length; i++) {
    const value = (i / (days.length - 1)) * maxX;
    values.push(
      <text key={i} x={value} y={maxY} className="svg-label-x">
        {days[i]}
      </text>
    );
  }
  values.push(
    <text key={"last"} x={maxX / 2} y={maxY + 20} className="svg-label-x bold">
      All Users
    </text>
  );
  return values;
};

const generateVertical = (maxX, maxY, limits) => {
  const values = [];
  const step = (1 / limits.length) * maxY;
  for (let i = 0; i < limits.length; i++) {
    const value = (i / limits.length) * maxY;
    values.push(
      <text key={i} x={0} y={maxY - value - step} className="svg-label-y">
        {limits[i]}
      </text>
    );
  }
  return values;
};

const generateLines = (maxX, maxY, limits) => {
  const values = [];
  const step = (1 / limits.length) * maxY;
  for (let i = 0; i < limits.length; i++) {
    const value = (i / limits.length) * maxY;
    values.push(
      <path
        key={i}
        d={`M 0 ${value}  L ${maxX} ${value}`}
        stroke="#F2F4F7"
        strokeLinecap="round"
      />
    );
  }
  return values;
};

export default function DailyActivityChart({ activity = [] }) {
  const exportRef = useRef();
  const [days, setDays] = useState([]);
  const [values, setValues] = useState([]);
  const [limits, setLimits] = useState([]);
  const activityChartOption = useSelector(selectDashboardActivityChartOption);

  const dispatch = useDispatch();

  useEffect(() => {
    if (activity?.length) {
      setDays(activity.map((item) => item.date));
      setValues(activity.map((item) => item.minutes));
      let min = activity[0].minutes,
        max = activity[0].minutes;
      activity.map((item) => {
        min = Math.min(item.minutes, min);
        max = Math.max(item.minutes, max);
      });
      //todo, ali.m make clean calculation
      const limits = [];
      min = min / 2;
      const diff = max - min;
      for (let i = 0; i < 6; i++) {
        limits.push((min + (diff / 5) * i).toFixed(1));
      }
      setLimits(limits);
    }
  }, [activity]);

  const xLabels = useMemo(
    () => generateHorizontal(SVG_WIDTH, SVG_HEIGHT, days),
    [days]
  );
  const yLabels = useMemo(
    () => generateVertical(SVG_WIDTH, SVG_HEIGHT, limits),
    [limits]
  );
  const lines = useMemo(
    () => generateLines(SVG_WIDTH, SVG_HEIGHT, limits),
    [limits]
  );

  return (
    <div className="block">
      <div className="parent">
        <div className="chart" ref={exportRef}>
          <div className="header">
            <div className="title justify-title width-98">
            {
              activityChartOption == i18next.t("labels.charts.options.default") ? 
                (<div>{i18next.t("labels.daily_activity")}</div>) :
                (<div>{i18next.t("labels.monthly_activity")}</div>)
            }
              
              <div>
                <div className="button-container chart-options-container">
                  {CHART_OPTIONS.map(({ key, value, label }) => (
                      <button
                        key={key + value}
                        onClick={() => dispatch(setDashboardActivityChartOption(value))}
                        disabled={value === activityChartOption}
                      >
                        {label}
                      </button>
                    ))}
                </div>
                <img
                  className="export-btn export-btn-black"
                  alt="tuhoon"
                  src={resourcesImage}
                  onClick={async () => {
                    exportButtonDisplayNone();
                    chartOptionsDisplayNone();
                    await exportAsImage(
                      exportRef.current,
                      activityChartOption === i18next.t("labels.charts.options.default") ? 
                      i18next.t("labels.daily_activity") : i18next.t("labels.monthly_activity")
                    );
                    exportButtonDisplayBlock();
                    chartOptionsDisplayBlock();
                  }}
                />
              </div>
            </div>
            {
              activityChartOption == i18next.t("labels.charts.options.default") ? 
                (<div className="days">{i18next.t("labels.last_7_days")}</div>) :
                (<div className="days">{i18next.t("labels.current_year")}</div>)
            }
          </div>
          <div className="body pt-1">
            {values?.every((element) => element === null) ||
            activity.length === 0 ? (
              <div className="m-auto">
                <p>{i18next.t("labels.no_data_available")}</p>
              </div>
            ) : (
              <>
                <p className="daily-unit-label">(mins)</p>
                <svg
                  version="1.2"
                  xmlns="http://www.w3.org/2000/svg"
                  overflow={"visible"}
                  strokeWidth="1"
                  width="500"
                  height="220"
                  viewBox={`0 0 ${SVG_WIDTH} 220`}
                >
                  <g className="labels x-labels">
                    {xLabels.map((item) => item)}
                  </g>
                  <g className="labels y-labels">
                    {yLabels.map((item) => item)}
                  </g>

                  {lines}

                  <svg
                    width={`${SVG_WIDTH}`}
                    height={`${SVG_HEIGHT}`}
                    viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d={
                        generatePathPoints(
                          SVG_HEIGHT,
                          SVG_WIDTH,
                          values,
                          limits
                        )[0]
                      }
                      stroke="#5AC2B4"
                      strokeWidth="2"
                    />
                  </svg>

                  <svg
                    width={`${SVG_WIDTH}`}
                    height={`${SVG_HEIGHT}`}
                    viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d={
                        generatePathPoints(
                          SVG_HEIGHT,
                          SVG_WIDTH,
                          values,
                          limits
                        )[1]
                      }
                      fill="url(#paint0_linear_262_41522)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_262_41522"
                        x1="216"
                        y1="0"
                        x2="216"
                        y2="147.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#5AC2B4" stopOpacity="0.2" />
                        <stop offset="1" stopColor="#5AC2B4" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </svg>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
