import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./modal.scss";
import { selectAppModal } from "redux/slices/appSlice";
import { MODALS } from "../common";
import UserOnboarding from "./userOnboarding";
import UserDelete from "./userDelete";
import InviteMember from "./inviteMember/inviteMember";
import MemberDelete from "./memberDelete";
import EditMember from "./editMember";
import ChangePassword from "./changePassword";

function AppModal() {
  const modal_type = useSelector(selectAppModal);
  const [modal, setModal] = useState(false);
  const [hide, setHide] = useState(false);
  useEffect(() => {
    let modalValue = false;
    let timer = null;
    switch (modal_type) {
      case MODALS.NONE:
        modalValue = false;
        break;
      case MODALS.USER_ONBOARDING:
        modalValue = <UserOnboarding />;
        break;
      case MODALS.USER_DELETE:
        modalValue = <UserDelete />;
        break;
      case MODALS.INVITE_MEMBER:
        modalValue = <InviteMember />;
        break;
      case MODALS.MEMBER_DELETE:
        modalValue = <MemberDelete />;
        break;
      case MODALS.EDIT_MEMBER:
        modalValue = <EditMember />;
        break;
      case MODALS.CHANGE_PASSWORD:
        modalValue = <ChangePassword />;
        break;
      default:
        modalValue = false;
        break;
    }
    if (modalValue !== false) {
      setModal(modalValue);
    } else if (modal) {
      setHide(true);
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        setHide(false);
        setModal(modalValue);
      }, 500);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [modal_type]);

  return (
    (modal && (
      <div className={`modal-container ${hide ? "hide" : ""}`}>{modal}</div>
    )) ||
    false
  );
}

export default memo(AppModal);
