import { store } from "redux/store";
import { logout, selectAccountAccessToken, selectAccountRefreshToken, setAccountAccessToken, updateAccessToken } from "redux/slices/accountSlice";
import AppLogic from "AppLogic";



const setup = () => {
  AppLogic.getAxios().interceptors.request.use(
    (config) => {
      const token = selectAccountAccessToken(store.getState());
      const headers = Object.fromEntries(
        Object.entries(config.headers).filter(([_, v]) => !!v)
      );
      config.headers = headers;
      if (token) {
        config.headers["authorization"] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  AppLogic.getAxios().interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig?.url !== "/auth/signin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig?._retry) {
          originalConfig._retry = true;

          try {
            const rs = await AppLogic.getAxios().post("/auth/refreshtoken", {
              refreshToken: selectAccountRefreshToken(store.getState()),
            });

            const { accessToken } = rs.data;

            await store.dispatch(setAccountAccessToken(accessToken));

            return AppLogic.getAxios()(originalConfig);
          } catch (_error) {
            await dispatch(logout());
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
