import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FETCH_STATUS, ROLES } from "../../common";
import authService from "../../services/auth.service";

// #region Initial State
const initialState = {
  logged: false,
  user: null,
  name: null,
  accessToken: null,
  refreshToken: null,
  error: null,
  status: FETCH_STATUS.IDLE,
  role: ROLES.READ_ONLY,
  updating: FETCH_STATUS.IDLE,
};
// #endregion

// #region Thunk Actions

export const loginAccount = createAsyncThunk(
  "account/loginAccount",
  async (body) => {
    try {
      const user = await authService.login(body.email, body.password);
      const isAdmin = user.roles.includes(ROLES.ADMMIN);
      return {
        user,
        name: user.firstName || user.email,
        role: isAdmin ? ROLES.ADMMIN : ROLES.READ_ONLY, // to be updated later for more dynamic way
        logged: true,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log("message ==>", error, message);
      throw error;
    }
  }
);

export const updateAccount = createAsyncThunk(
  "account/updateAccount",
  async (body) => {
    try {
      const user = await authService.update(body);
      const isAdmin = user.roles.includes(ROLES.ADMMIN);
      // to be checked
      return {
        user,
        name: user.firstName || user.email,
        role: isAdmin ? ROLES.ADMMIN : ROLES.READ_ONLY, // to be updated later for more dynamic way
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log("message ==>", error, message);
      throw error;
    }
  }
);
// #endregion

// region Main Reducer
export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    saveUser: (state, action) => {
      const user = action.payload;
      const isAdmin = user.roles.includes(ROLES.ADMMIN);
      return {
        ...state,
        user,
        name: user.firstName || user.email,
        role: isAdmin ? ROLES.ADMMIN : ROLES.READ_ONLY, // to be updated later for more dynamic way
        logged: true,
      };
    },
    updateAccessToken: (state, action) => {
      const accessToken = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          accessToken,
        },
      };
    },
    logout: () => {
      return initialState;
    },
    setAccountAccessToken: (state, action) => {
      state.accessToken = action?.payload;
    },
    setAccountRefreshToken: (state, action) => {
      state.refreshToken = action?.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginAccount.pending, (state) => {
        state.status = FETCH_STATUS.LOADING;
        state.error = null;
      })
      .addCase(loginAccount.fulfilled, (state, action) => {
        return { ...state, ...action.payload, status: FETCH_STATUS.SUCCESS };
      })
      .addCase(loginAccount.rejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(updateAccount.pending, (state) => {
        state.status = FETCH_STATUS.LOADING;
        state.error = null;
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        return { ...state, ...action.payload, status: FETCH_STATUS.SUCCESS };
      })
      .addCase(updateAccount.rejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
        state.error = action.error.message;
      });
  },
});
// #endregion

//#region actions
export const {
  setAccountAccessToken,
  setAccountRefreshToken
} = accountSlice.actions;
//#endregion

// #region selectors
export const selectAccountName = (state) => {
  return state.account?.name || "";
};
export const selectAccountEmail = (state) => {
  return state.account?.user?.email || "";
};
export const selectAccountReady = (state) => {
  return (
    state.account.status != FETCH_STATUS.IDLE &&
    state.account.status != FETCH_STATUS.LOADING
  );
};
export const accountLogged = (state) => state.account.logged;

export const accountDetails = (state) => state.account.user;

export const selectAccountError = (state) => state.account.error;

export const accountRole = (state) => state.account.role;

export const selectAccountAccessToken = (state) => state.account?.accessToken;

export const selectAccountRefreshToken = (state) => state.account?.refreshToken;

export const isAdminAccount = (state) =>
  state.account.user?.roles.includes(ROLES.ADMMIN);

export const isReadOnlyAccount = (state) =>
  state.account.user?.roles.includes(ROLES.READ_ONLY);
// #endregion

export const { saveUser, updateAccessToken, logout } = accountSlice.actions;

export default accountSlice.reducer;
