import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./tableNavigation.scss";
import ArrowLeftSVG from "../../resources/icons/arrow-left.svg";

function ButtonNumber({ isSelected, noSelect, number, onPress }) {
  const onClick = useCallback(() => {
    onPress?.(number);
  }, [number, onPress]);
  return (
    <div className={`number ${isSelected ? "selected" : ""} ${noSelect ? "noselect" : ""}`} onClick={onClick}>
      {number}
    </div>
  );
}

export default function TableNavigation({
  currentPage = 1,
  pageCount = 3,
  onPageChange,
}) {
  const [pages, setPages] = useState([]);
  const startPage =  useMemo(() => {
    return Math.max(currentPage - 4, 0);
  }, [currentPage, pageCount])
  const endPage =  useMemo(() => {
    return Math.min(startPage + 8, pageCount);
  }, [startPage, pageCount])
  useEffect(() => {
    const array = [];
    for (let i = startPage; i < endPage; i++) {
      array.push(i + 1);
    }
    setPages(array);
  }, [currentPage, pageCount, setPages]);

  const onPress = useCallback(
    (number) => {
      if (pageCount == 0) return;
      number = Math.max(1, number);
      number = Math.min(pageCount, number);
      onPageChange(number);
    },
    [onPageChange, pageCount]
  );

  const onIncPage = useCallback(() => {
    onPress(currentPage + 1);
  }, [onPress, currentPage]);

  const onDecPage = useCallback(() => {
    onPress(currentPage - 1);
  }, [onPress, currentPage]);

  return (
    <div className="table-navigation">
      <div className="limits" onClick={onDecPage}>
        <img src={ArrowLeftSVG}></img>
      </div>
      {(startPage > 0) &&
        <ButtonNumber
          number={'...'}
          noSelect
        />}
      {pages.map((item, index) => {
        return (
          <ButtonNumber
            key={`${item}_${index}`}
            number={item}
            isSelected={currentPage === item}
            onPress={onPress}
          />
        );
      })}
        {(endPage < pageCount) &&
        <ButtonNumber
          number={'...'}
          noSelect
        />}
      <div className="limits reverse" onClick={onIncPage}>
        <img src={ArrowLeftSVG}></img>
      </div>
    </div>
  );
}
