import Utils from 'common/utils';
import React, { useEffect, useState } from 'react'
import './animatedCounter.scss'

const STEP = 20;
export default function AnimatedCounter({ start = 0, finish, useComma = false, percentage = false }) {
  const [current, setCurrent] = useState(start)

  useEffect(() => {
    let steps = 1;
    let timer = setInterval(() => {
      steps++;
      if (steps == STEP) {
        clearInterval(timer);
        setCurrent(finish);
      } else {
        if(percentage){
          setCurrent((start + (finish - start) / STEP * steps).toFixed(1));
        }
        else{
          setCurrent(Math.floor(start + (finish - start) / STEP * steps));
        }
      }
    }, 50);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    }
  }, [setCurrent, finish, percentage])

  return (
    <div>{useComma ? (Utils.numberWithCommas(current) || '0') : (current || '0')}</div>
  )
}
