import AnimatedCounter from "components/animatedCounter";
import i18next from "i18next";
import React, { useRef } from "react";
import ProgressBar from "../progressBar";
import "./cards.scss";
import {
  addShadow,
  exportAsImage,
  exportButtonDisplayBlock,
  exportButtonDisplayNone,
  chartOptionsDisplayBlock,
  chartOptionsDisplayNone,
  removeShadow,
} from "utils/exportAsImage";
import resourcesImage from "resources/icons/resources.svg";

export default function ObjectivesCard({ objectives }) {
  const exportRef = useRef();
  return (
    <div className="block">
      <div className="card purple" ref={exportRef}>
        <div className="title justify-title">
          <p className="m-0">{i18next.t("labels.team_activity")}</p>
          <img
            className="export-btn"
            alt="tuhoon"
            src={resourcesImage}
            onClick={async () => {
              exportButtonDisplayNone();
              chartOptionsDisplayNone();
              removeShadow();
              await exportAsImage(
                exportRef.current,
                i18next.t("labels.team_activity")
              );
              addShadow();
              exportButtonDisplayBlock();
              chartOptionsDisplayBlock();
            }}
          />
        </div>
        <div className="charts">
          {objectives?.map((item, index) => (
            <div className="row" key={index}>
              <div className="fixed-label">
                {item?.titles?.en || item?.titles?.ar || ""}
              </div>
              <ProgressBar
                width={145}
                height={8}
                progress={item?.value / 100}
              />
              <div className="percentage">
                <AnimatedCounter finish={item?.value} percentage />%
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
