import AnimatedCounter from "components/animatedCounter";
import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isAdminAccount } from "redux/slices/accountSlice";
import { setAppModal } from "redux/slices/appSlice";
import {
  selectTotalUsers,
  selectPlanExpiry,
} from "redux/slices/orginizationSlice";
import {
  fetchUsers,
  selectTotalInvitedUsers,
  selectUsersReady,
  selectUsersTotalPages,
} from "redux/slices/usersSlice";
import { MODALS } from "../../common";
import Button from "../button";
import SearchUsers from "./searchUsers";
import Table from "./table";
import "./usersTable.scss";

const PAGE_SIZE = 40; // check if we need this to be dynamic ?
export default function UsersTable() {
  const usersCount = useSelector(selectTotalUsers);
  const planExpiry = useSelector(selectPlanExpiry);
  const usersActive = useSelector(selectTotalInvitedUsers);
  const usersReady = useSelector(selectUsersReady);
  const totalPages = useSelector(selectUsersTotalPages);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchOrder, setSearchOrder] = useState("desc");
  const [searchSortedBy, setSearchSortedBy] = useState("status");
  const [currentPage, setCurrentPage] = useState(0);
  const isAdmin = useSelector(isAdminAccount);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchUsers({
        page: currentPage,
        size: PAGE_SIZE,
        search: searchQuery,
        order: searchOrder,
        sortedBy: searchSortedBy,
      })
    );
  }, [currentPage, dispatch, searchQuery, searchOrder]);

  useEffect(() => {
    if (currentPage >= totalPages && totalPages != 0) {
      setCurrentPage(totalPages - 1);
    }
  }, [totalPages]);

  const onAddNew = useCallback(() => {
    dispatch(setAppModal(MODALS.USER_ONBOARDING));
  }, [dispatch, setAppModal]);

  const onSearch = useCallback((text) => {
    setCurrentPage(0);
    setSearchQuery(text);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setAppModal(MODALS.NONE));
    };
  }, []);

  const onOrder = useCallback(
    (sortedBy, order) => {
      setSearchSortedBy(sortedBy);
      setSearchOrder((oldValue) => (oldValue === "desc" ? "asc" : "desc"));
    },
    [searchOrder]
  );
  return (
    <div className="users-table">
      <div className="header">
        <div className="text">
          <div className="title">{i18next.t("labels.all_users")}</div>
          <div className="count">
            <AnimatedCounter finish={usersActive} />
            &nbsp;{`${i18next.t("labels.of")} ${usersCount} users`}
          </div>
        </div>

        <div className="right-container">
          <SearchUsers onSearch={onSearch} />
          <Button
            disabled={!isAdmin || new Date(planExpiry) < new Date()}
            title={i18next.t("buttons.add_new")}
            onPress={onAddNew}
          />
        </div>
      </div>

      <div className="body">
        <Table
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          isLoading={!usersReady}
          onOrder={onOrder}
          order={searchOrder}
          sortedBy={searchSortedBy}
        />
      </div>
    </div>
  );
}
