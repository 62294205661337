export const NAVIGATION_ITEM = {
  HOME: "HOME",
  USERS: "USERS",
  RESOURCES: "RESOURCES",
  SUPPORT: "SUPPORT",
  SETTINGS: "SETTINGS",
};
Object.freeze(NAVIGATION_ITEM);

export const FETCH_STATUS = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};
Object.freeze(FETCH_STATUS);

export const MODALS = {
  NONE: "NONE",
  USER_ONBOARDING: "USER_ONBOARDING",
  USER_DELETE: "USER_DELETE",
  INVITE_MEMBER: "INVITE_MEMBER",
  MEMBER_DELETE: "MEMBER_DELETE",
  EDIT_MEMBER: "EDIT_MEMBER",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
};

Object.freeze(MODALS);

export const ROLES = {
  READ_ONLY: "ROLE_READONLY",
  ADMMIN: "ROLE_ADMIN",
};

Object.freeze(ROLES);

export const ROLES_VALUES = {
  ROLE_READONLY: "readOnly",
  ROLE_ADMIN: "admin",
};

export const CHART_OPTIONS = [
  {
    key: 0, 
    value: "Default",
    label: "Default"
  }, 
  {
    key: 1, 
    value: "Yearly",
    label: "Yearly"
  }
]
