import i18next from "i18next";
import React, { useCallback, useMemo, useState } from "react";
import "./table.scss";
import Arrow from "../../resources/icons/arrow-up.svg";
import TableRecord from "./tableRecord";
import TableNavigation from "./tableNavigation";
import { useDispatch, useSelector } from "react-redux";
import { setAppModal } from "redux/slices/appSlice";
import { MODALS } from "common";
import { getPages, selectTotalPages } from "redux/slices/teamMembersSlice";
import Loader from "components/loader";
import { isAdminAccount } from "redux/slices/accountSlice";

const SORT_ORDER = {
  NONE: "NONE",
  DESC: "DESC",
  ASCE: "ASCE",
};
Object.freeze(SORT_ORDER);

export default function Table(props) {
  const { currentPage, setCurrentPage, isLoading } = props;
  const dispatch = useDispatch();
  const allUserPages = useSelector(getPages);
  const reduxCurrentPageUsers = allUserPages ? allUserPages[currentPage] : [];
  const [sortByRole, setSortByRole] = useState(SORT_ORDER.NONE);
  const [sortByStatus, setSortByStatus] = useState(SORT_ORDER.NONE);
  const isAdmin = useSelector(isAdminAccount);

  const currentPageUsers = useMemo(() => {
    if (sortByRole !== SORT_ORDER.NONE) {
      const value = (sortByRole === SORT_ORDER.ASCE) ? 1 : -1;
      return [...reduxCurrentPageUsers].sort((a,b) => a.roles?.[0] > b.roles?.[0] ? value : -value);
    } else if (sortByStatus !== SORT_ORDER.NONE) {
      const value = (sortByStatus === SORT_ORDER.ASCE) ? 1 : -1;
      return [...reduxCurrentPageUsers].sort((a,b) => a.status > b.status ? value : -value);
    } else {
      return reduxCurrentPageUsers;
    }
  }, [reduxCurrentPageUsers, sortByRole, sortByStatus])

  const totalPages = useSelector(selectTotalPages);

  const onDelete = useCallback(
    ({ email }) => {
      dispatch(setAppModal({ modal: MODALS.MEMBER_DELETE, data: { email } }));
    },
    [dispatch]
  );

  const onSortRole = useCallback(
    () => {
      setSortByStatus(SORT_ORDER.NONE);
      setSortByRole((oldvalue) => oldvalue == SORT_ORDER.ASCE ? SORT_ORDER.DESC : SORT_ORDER.ASCE );
    },
    [setSortByRole, setSortByStatus],
  );

  const onSortStatus = useCallback(
    () => {
      setSortByRole(SORT_ORDER.NONE);
      setSortByStatus((oldvalue) => oldvalue == SORT_ORDER.ASCE ? SORT_ORDER.DESC : SORT_ORDER.ASCE );
    },
    [setSortByRole, setSortByStatus],
  );

  const onEdit = useCallback(
    ({ email, roles }) => {
      dispatch(
        setAppModal({ modal: MODALS.EDIT_MEMBER, data: { email, roles } })
      );
    },
    [dispatch]
  );

  const onPageChange = useCallback(
    (page) => {
      setCurrentPage(page - 1);
    },
    [setCurrentPage]
  );

  return (
    <div className="user-table">
      <div className="header">
        <div className="username">{i18next.t("labels.user_name")}</div>
        <div className="status" onClick={onSortRole}>
          <div>{i18next.t("labels.role")}</div>
          <img className={`icon ${sortByRole == SORT_ORDER.ASCE ? 'flip' : ''}`} src={Arrow} alt="arrow up"/>
        </div>
        <div className="status" onClick={onSortStatus}>
          <div>{i18next.t("labels.status")}</div>
          <img className={`icon ${sortByStatus == SORT_ORDER.ASCE ? 'flip' : ''}`}  src={Arrow} alt="arrow up"/>
        </div>
        <div className="last" />
        <div className="last" />
      </div>
      <div className="body">
        {isLoading && <Loader />}
        {currentPageUsers?.map((item, index) => (
          <TableRecord
            disabled={!isAdmin}
            {...item}
            key={item.email + item.id + index}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        ))}
      </div>
      <div className="footer">
        <TableNavigation
          currentPage={currentPage + 1}
          onPageChange={onPageChange}
          pageCount={totalPages}
        />
      </div>
    </div>
  );
}
