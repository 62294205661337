import React, { useEffect, useMemo, useRef, useState } from "react";
import "./table.scss";

const HORIZONTAL_LINES = ["0%", "20%", "40%", "60%", "80%", "100%"];
const VERTICAL_LINES = ["0%", "100%"];

const HAPPY_FULL = "High";
const NORMAL_FULL = "Mid";
const SAD_FULL = "Low";
const HAPPY = HAPPY_FULL[0];
const NORMAL = NORMAL_FULL[0];
const SAD = SAD_FULL[0];
const TREND_FULL = "Trend line";

function Column({ item, height, max, min, title, pos, index, getRect }) {
  const sum = item.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const percentageItem0 = (item[0] / sum) * 100;
  const percentageItem1 = (item[1] / sum) * 100;
  const percentageItem2 = (item[2] / sum) * 100;
  const [c1, setC1] = useState(0);
  const [c2, setC2] = useState(0);
  const [c3, setC3] = useState(0);
  const timer = useRef(0);
  const timer2 = useRef(0);

  const ref = useRef(null);
  const refColor = useRef("");
  const selectedIndex = useMemo(() => {
    if (item[0] >= item[1] && item[0] >= item[2]) return 0;
    if (item[1] >= item[0] && item[1] >= item[2]) return 1;
    if (item[2] >= item[0] && item[2] >= item[1]) return 2;
  }, [item]);

  useEffect(() => {
    if (max) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        timer.current = null;
        setC1((item[0] / max) * height);
        setC2((item[1] / max) * height);
        setC3((item[2] / max) * height);
      }, 250);
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [item, max, min, height]);

  useEffect(() => {
    if (selectedIndex == 0) refColor.current = "#36B368";
    if (selectedIndex == 1) refColor.current = "#3794C9";
    if (selectedIndex == 2) refColor.current = "#CF4771";
  }, [selectedIndex]);

  useEffect(() => {
    if (timer2.current) clearTimeout(timer2.current);
    timer2.current = setTimeout(() => {
      timer2.current = null;
      getRect(ref.current, refColor.current);
    }, 1000);
    return () => {
      if (timer2.current) {
        clearTimeout(timer2.current);
      }
    };
  }, [ref]);

  return (
    <div className="column-container" style={{ left: `${pos - 26}px` }}>
      <div
        className="column"
        style={{ height: `${c1}px`, opacity: c1 == 0 ? 0 : 1 }}
      >
        <div
          ref={selectedIndex == 0 ? ref : null}
          className="hide-show mood-column"
          style={{
            background: selectedIndex != 0 ? "#DCF4E6" : "#36B368",
          }}
        >
          <div
            style={{
              display: "block",
              color: selectedIndex == 0 ? "#DCF4E6" : "#36B368",
            }}
          >
            <p className="mood-column-text">{HAPPY}</p>
          </div>
        </div>
        <div className="mood-tooltip" style={{ color: "#36B368" }}>
          {percentageItem0 % 1 != 0
            ? percentageItem0.toFixed(1)
            : percentageItem0}
          %
        </div>
      </div>
      <div
        className="column"
        style={{
          height: `${c2}px`,
          background: "#D3EAF3",
          opacity: c2 == 0 ? 0 : 1,
        }}
      >
        <div
          ref={selectedIndex == 1 ? ref : null}
          className="hide-show mood-column"
          style={{
            color: selectedIndex == 1 ? "#D3EAF3" : "#3794C9",
            background: selectedIndex != 1 ? "#D3EAF3" : "#3794C9",
          }}
        >
          <p className="mood-column-text">{NORMAL}</p>
        </div>
        <div className="mood-tooltip" style={{ color: "#3794C9" }}>
          {percentageItem1 % 1 != 0
            ? percentageItem1.toFixed(1)
            : percentageItem1}
          %
        </div>
      </div>
      <div
        className="column"
        style={{
          height: `${c3}px`,
          background: "#F7DFE6",
          opacity: c3 == 0 ? 0 : 1,
        }}
      >
        <div
          ref={selectedIndex == 2 ? ref : null}
          className="hide-show mood-column"
          style={{
            width: "16px",
            color: selectedIndex == 2 ? "#F7DFE6" : "#CF4771",
            background: selectedIndex != 2 ? "#F7DFE6" : "#CF4771",
          }}
        >
          <p className="mood-column-text">{SAD}</p>
        </div>
        <div className="mood-tooltip" style={{ color: "#CF4771" }}>
            {percentageItem2 % 1 != 0
              ? percentageItem2.toFixed(1)
              : percentageItem2}
            %
          </div>
      </div>
      <div className="title">{title}</div>
    </div>
  );
}


function getVerticalNumbers(data) {
  let verticalNumbers = [];
  let numbers = [];

  data.forEach(entry => {
    const [ happy, normal, sad ] = entry;

    // Sad value
    verticalNumbers.push(sad);

    // Sum of sad and normal values
    verticalNumbers.push(sad + normal);

    // Sum of sad, normal, and happy values
    verticalNumbers.push(sad + normal + happy);

  });

  const min = Math.min(...verticalNumbers);
  const max = Math.max(...verticalNumbers);
  const midpoint = (min + max) / 2;
  const closest = verticalNumbers.reduce((prev, curr) => Math.abs(curr - midpoint) < Math.abs(prev - midpoint) ? curr : prev);

  numbers.push(min);
  numbers.push(closest);
  numbers.push(max);

  return numbers;
}

export default function MoodsTable({ items = [], width, height, titles = [] }) {
  const canvasRef = useRef(null);
  const arrayOfItems = useRef([]);
  const arrayOfColors = useRef([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [maxArray, setMaxArray] = useState([]);
  const [verticalValues, setVerticalValues] = useState([]);
  useEffect(() => {
    let min = Infinity;
    let max = 0;
    let maxItems = [];
    for (let i = 0; i < items.length; i++) {
      const value = items[i][0] + items[i][1] + items[i][2];
      if (value < min) min = value;
      if (value > max) max = value;
    }

    setMin(min);
    setMax(max);
    for (let i = 0; i <= max; i++) {
      maxItems.push(i.toString());
    }
    setMaxArray(maxItems);
    setVerticalValues(getVerticalNumbers(items));
  }, [items, arrayOfItems, arrayOfColors, canvasRef]);

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");

    let timer = setTimeout(() => {
      timer = null;
      const R = canvasRef.current.getBoundingClientRect();
      let rect;
      let x1, y1, x2, y2;
      for (let i = 0; i < items.length - 1; i++) {
        rect = arrayOfItems.current[i].getBoundingClientRect();

        x1 = rect.x - R.x + 8;
        y1 = rect.y - R.y + 8;

        rect = arrayOfItems.current[i + 1].getBoundingClientRect();
        x2 = rect.x - R.x + 8;
        y2 = rect.y - R.y + 8;
      }
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [arrayOfItems, max, min, items, arrayOfColors, canvasRef]);

  return (
    <div className="mood-top-container">
      <div
        className="mood-container"
        style={{
          width: width + "px",
          height: height + "px",
        }}
      >
        {/* Lines */}
        {HORIZONTAL_LINES.map((top, index) => (
          <div key={`H${index}`} className="horizontal-line" style={{ top }} />
        ))}
        {VERTICAL_LINES.map((left, index) => (
          <div key={`V${index}`} className="vertical-line" style={{ left }} />
        ))}

        {/* Vertical Labels */}
        {maxArray.map((top, index) => {
          let topPercentage = 100 - (top / max) * 100 + "%";
          return (
            <div
              className="vertical-label"
              key={`V-LABELS${index}`}
              style={{ top: topPercentage }}
            >
              {verticalValues.includes(Number(top)) ? top : ''}
            </div>
          );
        })}

        {items.map((item, index) => {
          return (
            <Column
              key={`C${index}`}
              item={item}
              height={height * 1.0}
              min={min}
              max={max}
              pos={((index + 1) / (items.length + 1)) * width}
              title={titles[index] || ""}
              index={index + 1}
              getRect={(item, color) => {
                arrayOfItems.current.push(item);
                arrayOfColors.current.push(color);
              }}
            />
          );
        })}
        <canvas width={width * 1.0} height={height * 1.0} ref={canvasRef} />
        <div className="mood-bottom">
          <div
            className="mood-column-icon"
            style={{ color: "#36B368", background: "#DCF4E6" }}
          >
            <p className="mood-column-text">{HAPPY}</p>
          </div>
          <div className="mood-column-label">{HAPPY_FULL}</div>
          <div
            className="mood-column-icon"
            style={{ color: "#3794C9", background: "#D3EAF3" }}
          >
            <p className="mood-column-text">{NORMAL}</p>
          </div>
          <div className="mood-column-label">{NORMAL_FULL}</div>
          <div
            className="mood-column-icon"
            style={{ color: "#CF4771", background: "#F7DFE6" }}
          >
            <p className="mood-column-text">{SAD}</p>
          </div>
          <div className="mood-column-label">{SAD_FULL}</div>
        </div>
      </div>
    </div>
  );
}
