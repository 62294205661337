import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import resourcesService from "services/resources.service";
import { FETCH_STATUS } from "../../common";

// #region Initial State
const initialState = {
	categories: {},
	error: null,
	status: FETCH_STATUS.IDLE,
};
// #endregion

// #region Thunk Actions
export const fetchResourcesCategories = createAsyncThunk(
	"resources/fetchResourcesCategories",
  async () => {
    return await resourcesService.getResourcesCategories();
	}
);
// #endregion

// region Main Reducer
export const resourcesSlice = createSlice({
	name: "resources",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(fetchResourcesCategories.pending, (state) => {
				state.status = FETCH_STATUS.LOADING;
				state.error = null;
			})
			.addCase(fetchResourcesCategories.fulfilled, (state, action) => {
				state.categories = action.payload;
				state.status = FETCH_STATUS.SUCCESS;
			})
			.addCase(fetchResourcesCategories.rejected, (state, action) => {
				state.status = FETCH_STATUS.FAILED;
				state.error = action.error.message;
			});
	},
});
// #endregion

// #region actions
// export const {} = resourcesSlice.actions;
// #endregion

// #region selectors
export const selectResourcesCategories = (state) => {
	return state.resources?.categories?.data;
};
export const selectResourcesReady = (state) => {
	return (
		state.categories.status !== FETCH_STATUS.IDLE &&
		state.categories.status !== FETCH_STATUS.LOADING
	);
};
// #endregion

export default resourcesSlice.reducer;
