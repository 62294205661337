import React from "react";
import CompanyProfileCard from "components/cards/companyProfile";
import css from "./settings.module.scss";
import CompanyPlanCard from "components/cards/companyPlan";
import { useSelector } from "react-redux";
import {
  selectActiveUsers,
  selectExpiredUsers,
  selectOrginizationDetails,
  selectPlanExpiry,
  selectTotalUsers,
} from "redux/slices/orginizationSlice";

const GeneralScreen = (props) => {
  const allUsers = useSelector(selectTotalUsers);
  const activeUsers = useSelector(selectActiveUsers);
  const expiredUsers = useSelector(selectExpiredUsers);
  const expirationDate = useSelector(selectPlanExpiry);
  const orginizationInfo = useSelector(selectOrginizationDetails);
  return (
    <div className={css.wrapper}>
      <CompanyProfileCard className={css.card_flex} {...orginizationInfo} />
      <CompanyPlanCard
        allUsers={allUsers}
        activeUsers={activeUsers}
        expiredUsers={expiredUsers}
        expirationDate={expirationDate}
        className={css.card_flex}
      />
    </div>
  );
};

export default GeneralScreen;
