import React, { useCallback, useMemo, useState } from "react";
import i18next from "i18next";
import { Button } from "components";
import { useDispatch, useSelector } from "react-redux";
import {
  accountDetails,
  accountRole,
  updateAccount,
} from "redux/slices/accountSlice";
import { MODALS } from "common";
import { setAppModal } from "redux/slices/appSlice";

const ProfileScreen = (props) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(accountDetails);
  const userRole = useSelector(accountRole);
  const [user, setUser] = useState(userDetails);
  const [isSubmiiting, setIsSubmitting] = useState(false);

  const buttonStyle = useMemo(() => {
    return {
      width: "128px",
      paddingTop: "4px",
      paddingBottom: "4px",
      alignSelf: "flex-end",
      marginTop: "8px",
    };
  }, []);

  const disabledStyle = useMemo(() => {
    return {
      width: "128px",
      paddingTop: "4px",
      paddingBottom: "4px",
      alignSelf: "flex-end",
      marginTop: "8px",
      backgroundColor: "#fff",
      border: "1px solid rgb(208, 213, 221)",
    };
  }, []);

  const changePasswordStyle = useMemo(() => {
    return {
      // width: "139px",
      padding: "12px",
      alignSelf: "flex-start",
      marginTop: "8px",
      boxShadow: "0 1px 2px 0 rgba(16, 24, 40, 0.05)",
      border: "solid 1px #d0d5dd",
      backgroundColor: "#fff",
      color: "#344054",
    };
  }, []);

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleSubmitUserData = async () => {
    setIsSubmitting(true);
    const body = {
      firstName: user.firstName,
      familyName: user.familyName,
      userId: user.id,
    };
    await dispatch(updateAccount(body));
    setIsSubmitting(false);
    //TODO Yaaqba: make notification on success or failer
  };

  const handleChangePassword = useCallback(() => {
    dispatch(setAppModal(MODALS.CHANGE_PASSWORD));
  }, [dispatch]);

  return (
    <>
      <div className="contact-us">
        <div className="title">{i18next.t("labels.profile_information")}</div>
        <div className="line" />
        <form className="contact-us-form">
          <div className="section-container">
            <div className="section">
              <div className="form-title">{i18next.t("labels.first_name")}</div>
              <input
                placeholder={i18next.t("labels.your_name")}
                value={user.firstName}
                onChange={handleChange}
                name="firstName"
              />
            </div>
            <div className="section">
              <div className="form-title">
                {i18next.t("labels.family_name")}
              </div>
              <input
                placeholder={i18next.t("labels.your_family")}
                value={user.familyName}
                onChange={handleChange}
                name="familyName"
              />
            </div>
          </div>
          <div className="section-container">
            <div className="section">
              <div className="form-title">{i18next.t("labels.email")}</div>
              <input
                disabled
                placeholder={i18next.t("labels.email")}
                value={user.email}
              />
            </div>
          </div>
          <div className="section-container">
            <div className="section">
              <div className="form-title">{i18next.t("labels.role")}</div>
              <input disabled value={i18next.t(`roles.${userRole}`)} />
            </div>
          </div>
          {/* TODO: Yaaqba: on desing we dont have this button, keep it here until we know how should we save the data ?  */}
          <Button
            onPress={handleSubmitUserData}
            title={i18next.t("buttons.send")}
            style={buttonStyle}
            disabled={isSubmiiting}
            disabledStyle={disabledStyle}
          />
        </form>
      </div>
      <div className="contact-us extraMargin">
        <div className="title">{i18next.t("labels.profile_settings")}</div>
        <div className="line" />

        {/* TODO: Yaaqba: on desing we dont have this button, keep it here until we know how should we save the data ?  */}
        <Button
          title={i18next.t("buttons.change_password")}
          style={changePasswordStyle}
          onPress={handleChangePassword}
        />
      </div>
    </>
  );
};

export default ProfileScreen;
