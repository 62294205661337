import AppLogic from "AppLogic";

const requestResetPassword = async (email) => {
  const response = await AppLogic.getAxios()
    .post("reset-password", {
      email,
    });
  return response.data;
};

const resetPassword = async ({password, token}) => {
  const response = await AppLogic.getAxios()
    .post("reset-password/reset", {
      password,
      token,
    });
  return response.data;
};

const resetService = {
  resetPassword,
  requestResetPassword
};


export default resetService;
