import i18next, { use } from "i18next";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import MailSVG from "resources/icons/mail.svg";
import PlusSVG from "resources/icons/plus.svg";
import TrashSVG from "../../resources/icons/trash.svg";
import css from "./inviteMember.module.scss";
import { Button, Loader } from "components";
import { useDispatch } from "react-redux";
import { setAppModal } from "redux/slices/appSlice";
import { MODALS } from "common";
import invitationService from "services/invite-members.service";
import { fetchMembers } from "redux/slices/teamMembersSlice";
import Utils from "common/utils";

export default function InviteMember() {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([{ email: "", roles: "admin" }]);
  const [isLoading, setIsLoading] = useState(false);

  const addNewEmail = useCallback(() => {
    setUsers([...users, { email: "", roles: "admin" }]);
  }, [users]);

  const onCancel = useCallback(() => {
    dispatch(setAppModal(MODALS.NONE));
  }, [dispatch]);

  const errorStyle = useMemo(() => ({ borderColor: 'red' }), []);
  const cancelStyle = useMemo(() => {
    return {
      width: "145px",
      alignItem: "center",
      color: "#344054",
      backgroundColor: "#ffffff",
      shadowColor: "rgba(16, 24, 40, 0.05)",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowRadius: 2,
      shadowOpacity: 1,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#d0d5dd",
      padding: "3px 0",
    };
  }, []);

  const sendlStyle = useMemo(() => {
    return {
      width: "145px",
      alignItem: "center",
      shadowColor: "rgba(16, 24, 40, 0.05)",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowRadius: 2,
      shadowOpacity: 1,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#d0d5dd",
      padding: "3px 0",
    };
  }, []);

  const validateUsers = useCallback(
    () => {
      const allUsers = [...users];
      let inputValid = true;
      allUsers.map(async (user) => {
        if (!Utils.validateEmail(user.email)) {
          user.error = true;
          inputValid = false;
        }
        else {
          user.error = false;
        }
        return user;
      })
      setUsers(allUsers);
      return inputValid;
    },
    [users],
  )

  const onSubmit = useCallback(
    async () => {
      try {
        setIsLoading(true);
        if (validateUsers()) {
          const allUsers = [...users];
          await Promise.all(
            allUsers.map(async (user) => {
              if (user.email?.length > 0 && user.roles)
                return await invitationService.sendInvite(user);
            })
          );
          dispatch(fetchMembers());
          dispatch(setAppModal(MODALS.NONE));
        }
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [users, dispatch, validateUsers, setIsLoading],
  )

  const setRole = useCallback(
    (index, value) => {
      const newUsers = [...users];
      newUsers[index].roles = value;
      setUsers(newUsers);
    },
    [users],
  )

  const onDelete = useCallback(
    (index) => {
      const newUsers = users.filter((item,i) => i != index);
      setUsers(newUsers);
    },
    [users],
  )

  const setEmail = useCallback(
    (index, value) => {
      const newUsers = [...users];
      newUsers[index].email = value;
      newUsers[index].error = false;
      setUsers(newUsers);
    },
    [users],
  )

  return (
    <Fragment>
    <div className={css.container}>
      <div className={css.header}>
        <div className={css.title}>
          {i18next.t("labels.invite_team_member")}
        </div>
        <div className={css["title-supporting"]}>
          {i18next.t("paragraphs.add_teammate")}
        </div>
      </div>
      <div className={css.body}>
        <div className={css.inputContainer}>
          <div className={[css.body, css.flex2].join(" ")}>
            <div className={css.title}>{i18next.t("labels.email_address")}</div>
          </div>
          <div className={[css.body, css.flex1].join(" ")}>
            <div className={css.title}>{i18next.t("labels.access_level")}</div>
          </div>
        </div>
        {users.map((item, index) => (
          <div className={css.inputContainer} key={index}>
            <div className={[css.body, css.flex2].join(" ")}>
              <div className={css["email-input"]} style={item?.error ? errorStyle : null}>
                <img src={MailSVG} alt="mail"></img>
                <input
                  placeholder={i18next.t("labels.teammate_email_placeholder")}
                  onChange={(e) => setEmail(index, e.target.value)}
                  value={item?.email}
                />
              </div>
            </div>
            <div className={[css.body, css.flex1].join(" ")}>
              <div className={css["email-input"]}>
                <select value={item?.roles} onChange={(e) => setRole(index, e.target.value)}>
                  <option value={"admin"}>{i18next.t("roles.admin")}</option>
                  <option value="readOnly">{i18next.t("roles.read")}</option>
                </select>
              </div>
            </div>
            {index != 0 ? <div className={css.delete} onClick={() => onDelete(index)}>
              <img src={TrashSVG} />
            </div>
              :
              null}
          </div>
        ))
        }
        <div className={css.add} onClick={addNewEmail}>
          <img src={PlusSVG} alt="add"></img>
          <span>{i18next.t("labels.add_another_team")}</span>
        </div>
        <div className={css.buttons_container}>
          <Button title={"Cancel"} style={cancelStyle} onPress={onCancel} />
          <Button title={"Send"} style={sendlStyle} onPress={onSubmit} />
        </div>
      </div>
    </div>
    {isLoading ? <Loader/> : null}
    </Fragment>
  );
}
