import React, { useCallback, useEffect, useRef } from 'react'
import './searchUsers.scss'
import SearchImage from '../../resources/icons/search.svg'
import i18next from 'i18next'

export default function SearchUsers({ onSearch }) {
  const timer = useRef(null);
  const onChange = useCallback(
    (e) => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        timer.current = null;
        onSearch(e?.target?.value);
      }, 500);
    },
    [onSearch],
  )

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    }
  }, []);


  return (
    <div className='search-users'>
      <img className='image' src={SearchImage} />
      <input className='search' type={'text'} placeholder={i18next.t("input.search_users")} onChange={onChange} />
    </div>
  )
}
