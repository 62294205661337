import i18next from "i18next";
import React, { useCallback, useMemo } from "react";
import "./userDelete.scss";
import { Button } from "components";
import { useDispatch, useSelector } from "react-redux";
import { selectAppModalData, setAppModal } from "redux/slices/appSlice";
import { MODALS } from "common";
import usersService from "services/users.service";
import { fetchUsers } from "redux/slices/usersSlice";

export default function UserDelete() {
  const dispatch = useDispatch();
  const email = useSelector(selectAppModalData(MODALS.USER_DELETE))?.email;
  const phoneNumber = useSelector(selectAppModalData(MODALS.USER_DELETE))?.phoneNumber;
  const onDelete = useCallback(async () => {
    const deleteMember = await usersService.removeUserFromOrginization({
      email, phoneNumber
    });
    if (deleteMember) {
      dispatch(fetchUsers());
      dispatch(setAppModal(MODALS.NONE));
    }
  }, [dispatch, email, phoneNumber]);

  const onCancel = useCallback(() => {
    dispatch(setAppModal(MODALS.NONE));
  }, [dispatch]);

  const cancelStyle = useMemo(() => {
    return {
      color: "#344054",
      backgroundColor: "#ffffff",
      shadowColor: "rgba(16, 24, 40, 0.05)",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowRadius: 2,
      shadowOpacity: 1,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#d0d5dd",
    };
  }, []);

  return (
    <div className="user-delete">
      <div className="title">{i18next.t("labels.delete_user")}</div>
      <div className="question">{i18next.t("paragraphs.delete_user")}</div>
      <div className="email">{email || phoneNumber}</div>
      <div className="buttons_container">
        <Button title={"Cancel"} style={cancelStyle} onPress={onCancel} />
        <Button title={"Send"} onPress={onDelete} />
      </div>
    </div>
  );
}
