import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isAdminAccount } from "redux/slices/accountSlice";
import { setAppModal } from "redux/slices/appSlice";
import {
  fetchMembers,
  getPages,
  selectMembersReady,
} from "redux/slices/teamMembersSlice";
import { MODALS } from "../../common";
import Button from "../button";
import Table from "./table";
import "./teamTable.scss";

const PAGE_SIZE = 20;
export default function TeamTable() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const isReady = useSelector(selectMembersReady);
  const allUserPages = useSelector(getPages);
  const isAdmin = useSelector(isAdminAccount);

  useEffect(() => {
    if (!isReady || !allUserPages[currentPage]) {
      dispatch(fetchMembers({ page: currentPage, size: PAGE_SIZE }));
    }
  }, [currentPage, dispatch, isReady, allUserPages]);

  const onAddNew = useCallback(() => {
    dispatch(setAppModal(MODALS.INVITE_MEMBER));
  }, [dispatch, setAppModal]);

  useEffect(() => {
    return () => {
      dispatch(setAppModal(MODALS.NONE));
    };
  }, []);

  return (
    <div className="users-table">
      <div className="header">
        <div className="text">
          <div className="title">{i18next.t("labels.team_memebers")}</div>
        </div>
        <div className="right-container">
          <Button
            title={i18next.t("buttons.invite_member")}
            onPress={onAddNew}
            disabled={!isAdmin}
          />
        </div>
      </div>
      <div className="body">
        <Table
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          isLoading={!isReady}
        />
      </div>
    </div>
  );
}
