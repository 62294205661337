import React, { useEffect, useState } from "react";
import "./App.css";
import AppLogic from "./AppLogic";
import { AppModal } from "modals";
import AppRouter from "router/router";
import LoginRouter from "router/loginRouter";
import { useSelector } from "react-redux";
import { accountLogged } from "redux/slices/accountSlice";

function App() {
  const [loaded, setLoaded] = useState(false);
  const userLogged = useSelector(accountLogged);
  useEffect(() => {
    (async () => {
      await AppLogic.Init();
      setLoaded(true);
    })();
  }, []);

  const Router = userLogged ? AppRouter : LoginRouter;

  return !loaded ? null : (
    <div className="App">
      <Router />
      <AppModal />
    </div>
  );
}

export default App;
