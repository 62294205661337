import translationEN from "./en/translations.json";

const resources = {
  en: {
    translation: translationEN
  }
}

export {
  resources
}