import React, { useCallback, useEffect, useState } from 'react'
import './tableNavigation.scss'
import ArrowLeftSVG from '../../resources/icons/arrow-left.svg'

function ButtonNumber({ isSelected, number, onPress }) {
  const onClick = useCallback(
    () => {
      onPress(number);
    },
    [number, onPress],
  )
  return <div className={`number ${isSelected ? 'selected' : ''}`} onClick={onClick}>{number}</div>
}

export default function TableNavigation({ currentPage = 1, pageCount = 3, onPageChange }) {
  const [pages, setPages] = useState([]);
  useEffect(() => {
    const array = [];
    for (let i = 0; i < pageCount; i++) {
      array.push(i + 1);
    }
    setPages(array);
  }, [currentPage, pageCount, setPages]);

  const onPress = useCallback(
    (number) => {
      number = Math.max(1, number);
      number = Math.min(pageCount, number);
      onPageChange(number);
    }, [onPageChange, pageCount])

  const onIncPage = useCallback(
    () => {
      onPress(currentPage + 1);
    },
    [onPress, currentPage],
  )

  const onDecPage = useCallback(
    () => {
      onPress(currentPage - 1);
    },
    [onPress, currentPage],
  )


  return (
    <div className='table-navigation'>
      <div className='limits' onClick={onDecPage}><img src={ArrowLeftSVG}></img></div>
      {pages.map((item, index) => {
        return <ButtonNumber key={`${item}_${index}`} number={item} isSelected={currentPage === item} onPress={onPress} />
      })}
      <div className='limits reverse' onClick={onIncPage}><img src={ArrowLeftSVG}></img></div>
    </div>
  )
}
