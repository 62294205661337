import i18next from "i18next";
import React, { useCallback, useMemo, useState } from "react";
import MailSVG from "resources/icons/mail.svg";
import css from "./editMember.module.scss";
import { Button } from "components";
import { useDispatch, useSelector } from "react-redux";
import { selectAppModalData, setAppModal } from "redux/slices/appSlice";
import { MODALS, ROLES_VALUES } from "common";
import invitationService from "services/invite-members.service";
import usersService from "services/users.service";

export default function EditMember() {
  const dispatch = useDispatch();
  const email =
    useSelector(selectAppModalData(MODALS.EDIT_MEMBER))?.email || "";
  const currentRole = useSelector(
    selectAppModalData(MODALS.EDIT_MEMBER)
  )?.roles;
  // const [email, setEmail] = useState("");

  const [role, setRole] = useState(ROLES_VALUES[currentRole]);

  const onCancel = useCallback(() => {
    dispatch(setAppModal(MODALS.NONE));
  }, [dispatch]);

  const cancelStyle = useMemo(() => {
    return {
      width: "145px",
      alignItem: "center",
      color: "#344054",
      backgroundColor: "#ffffff",
      shadowColor: "rgba(16, 24, 40, 0.05)",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowRadius: 2,
      shadowOpacity: 1,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#d0d5dd",
      padding: "3px 0",
    };
  }, []);

  const sendlStyle = useMemo(() => {
    return {
      width: "145px",
      alignItem: "center",
      shadowColor: "rgba(16, 24, 40, 0.05)",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowRadius: 2,
      shadowOpacity: 1,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#d0d5dd",
      padding: "3px 0",
    };
  }, []);

  const onSubmit = async () => {
    const updatedRoles = await usersService.updateMemberRole({ email, role });
    if (updatedRoles) {
      dispatch(setAppModal(MODALS.NONE));
    }
  };

  return (
    <div className={css.container}>
      <div className={css.header}>
        <div className={css.title}>
          {i18next.t("labels.invite_team_member")}
        </div>
        <div className={css["title-supporting"]}>
          {i18next.t("paragraphs.add_teammate")}
        </div>
      </div>
      <div className={css.body}>
        <div className={css.inputContainer}>
          <div className={[css.body, css.flex2].join(" ")}>
            <div className={css.title}>{i18next.t("labels.email_address")}</div>
            <div className={css["email-input"]}>
              <img src={MailSVG} alt="mail"></img>
              <input
                placeholder={i18next.t("labels.teammate_email_placeholder")}
                disabled
                value={email}
              />
            </div>
          </div>
          <div className={[css.body, css.flex1].join(" ")}>
            <div className={css.title}>{i18next.t("labels.access_level")}</div>
            <div className={css["email-input"]}>
              <select value={role} onChange={(e) => setRole(e.target.value)}>
                <option value={"admin"}>{i18next.t("roles.admin")}</option>
                <option value="readOnly">{i18next.t("roles.read")}</option>
              </select>
            </div>
          </div>
        </div>
        <div className={css.buttons_container}>
          <Button title={"Cancel"} style={cancelStyle} onPress={onCancel} />
          <Button title={"Send"} style={sendlStyle} onPress={onSubmit} />
        </div>
      </div>
    </div>
  );
}
