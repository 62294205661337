import React, { useCallback } from "react";
import "./tableRecord.scss";
import TrashSVG from "../../resources/icons/trash.svg";
import Utils from "common/utils";

export default function TableRecord({
  email,
  phoneNumber,
  status,
  createdOn,
  onDelete,
  firsttime,
  disabled,
  firstName,
  lastName,
  gender,
  country,
}) {
  const onClick = useCallback(() => {
    if (disabled) return;
    if (email) {
      onDelete({ email });
    } else if (phoneNumber) {
      onDelete({ phoneNumber });
    }
  }, [disabled, email, phoneNumber, onDelete, status]);

  return (
    <div className={`table-record ${firsttime ? "firsttime" : "secondtime"}`}>
      <div className="email">{firstName || ""}</div>
      <div className="email ml-0">{email || ""}</div>
      <div className="email">{phoneNumber || ""}</div>
      <div className="email">{gender || ""}</div>
      <div className="email">{country || ""}</div>
      <div className="status">
        <div className={`text`}>{Utils.toDateString(createdOn)}</div>
      </div>
      <div className="status">
        <div className={`text ${status.toLowerCase()}`}>
          {Utils.capitalizeFirstLetter(status)}
        </div>
      </div>
      <div className={`delete ${disabled ? "disabled" : ""}`}>
        {/* <img src={TrashSVG} /> */}
      </div>
    </div>
  );
}
