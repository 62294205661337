import i18next from "i18next";
import React, { useState } from "react";
import "./cards.scss";

// TODO: Yaaqba: should pass props to controll the values inside & should add small animation
export default function CompanyProfileCard({ className = "", name, id }) {
  return (
    <div className={`card white noGap profile ${className}`}>
      <div className="title">{i18next.t("labels.organization_name")}</div>
      <div className="desc">{name}</div>
      <div className="title extraTopMargin">
        {i18next.t("labels.organization_id")}
      </div>
      <div className="desc">{id}</div>
    </div>
  );
}
