import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import usersService from "services/users.service";
import { FETCH_STATUS } from "../../common";

// #region Initial State
const initialState = {
  users: [],
  error: null,
  status: FETCH_STATUS.IDLE,
  totalPages: 0,
  totalItems: 0,
  fetchParams: {},
};
// #endregion

// #region Thunk Actions
export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (props = {}, { getState }) => {
    try {
      let { page = null, size = null, search = null, order = null, sortedBy = null } = props;
      const fetchParams = getState()?.users?.fetchParams;
      page = page ?? (fetchParams?.page || 0);
      size = size ?? (fetchParams?.size || 10);
      search = search ?? (fetchParams?.search || '');
      order = order ?? (fetchParams?.order || 'desc');
      sortedBy = sortedBy ??  (fetchParams?.sortedBy || 'status');
      const userLists = await usersService.getUsersList({ page, size, search, order, sortedBy });
      return {
        totalItems: userLists.total,
        users: userLists.employees,
        totalPages: Math.ceil(userLists.total / size),
        fetchParams: { page, size, search, order },
        error: null,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
// #endregion

// region Main Reducer
export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = FETCH_STATUS.LOADING;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          status: FETCH_STATUS.SUCCESS,
        };
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
        state.error = action.error.message;
      });
  },
});
// #endregion

// #region actions
export const {} = usersSlice.actions;
// #endregion

// #region selectors
export const selectUsersCount = (state) => {
  return state.users.usersCount;
};
export const selectUsersActive = (state) => {
  return state.users.activeUsers;
};
export const selectUsersAll = (state) => {
  return state.users.users;
};
export const selectUsersReady = (state) => {
  return (
    state.users.status != FETCH_STATUS.IDLE &&
    state.users.status != FETCH_STATUS.LOADING
  );
};

export const selectUsersTotalPages = (state) => state.users.totalPages;
export const selectTotalInvitedUsers = (state) => state.users.totalItems;

// #endregion

export default usersSlice.reducer;
