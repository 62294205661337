import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Sidebar } from "components";
import {
  DashboardPage,
  NoPage,
  ResourcesPage,
  SupportPage,
  UsersPage,
} from "pages";
import "./router.scss";
import SettingPage from "pages/settings";
import { useDispatch, useSelector } from "react-redux";
import { setAppActiveItem } from "redux/slices/appSlice";
import { NAVIGATION_ITEM } from "common";
import { getOrginizationinfo } from "redux/slices/orginizationSlice";
import {
  selectDashboardTargetCountry
} from "redux/slices/dashboardSlice"
function MyRoutes() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const targetCountry = useSelector(selectDashboardTargetCountry);

  useEffect(() => {
    let type = NAVIGATION_ITEM.HOME;
    switch (location.pathname) {
      case "/users":
        type = NAVIGATION_ITEM.USERS;
        break;
      case "/resources":
        type = NAVIGATION_ITEM.RESOURCES;
        break;
      case "/support":
        type = NAVIGATION_ITEM.SUPPORT;
        break;
      case "/settings":
        type = NAVIGATION_ITEM.SETTINGS;
        break;
      default:
        type = NAVIGATION_ITEM.HOME;
        break;
    }
    dispatch(setAppActiveItem(type));
  }, [dispatch, setAppActiveItem, location]);

  useEffect(() => {
    (async () => {
      await dispatch(getOrginizationinfo(targetCountry));
      setLoaded(true);
    })();
  }, []);

  return !loaded ? null : (
    <Routes>
      <Route path="/">
        <Route exact path="" element={<DashboardPage />} />
        <Route exact path="home" element={<DashboardPage />} />
        <Route exact path="users" element={<UsersPage />} />
        <Route exact path="resources" element={<ResourcesPage />} />
        <Route exact path="support" element={<SupportPage />} />
        <Route exact path="settings" element={<SettingPage />} />
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  );
}

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Sidebar />
      <div className="router-container">
        <MyRoutes />
      </div>
    </BrowserRouter>
  );
}
