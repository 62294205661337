import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoginPage } from "pages";
import "./router.scss";
import SetPasswordPage from "pages/setPassword/setPassword";
import ForgetPassword from "pages/forgetPassword";

function AnonymouseRoutes() {
  return (
    <Routes>
      <Route path="/">
        <Route exact path="" element={<LoginPage />} />
        <Route exact path="login" element={<LoginPage />} />
        <Route exact path="set-password" element={<SetPasswordPage />} />
        <Route exact path="reset-password" element={<SetPasswordPage isReset/>} />
        <Route exact path="forget-password" element={<ForgetPassword />} />

        <Route path="*" element={<LoginPage />} />
      </Route>
    </Routes>
  );
}

export default function LoginRouter() {
  return (
    <BrowserRouter>
      <div className="login-router">
        <AnonymouseRoutes />
      </div>
    </BrowserRouter>
  );
}
