import i18next from "i18next";
import React, { useCallback, useMemo, useState } from "react";
import { Button } from "components";
import css from "./forgetPassword.module.scss";
import TuhoonLogo from "../../resources/images/Logo-colored.svg";
import { useNavigate } from "react-router-dom";
import resetService from "services/resetPassword.service";
import Utils from "common/utils";

export default function ForgetPassword() {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [completed, setCompleted] = useState(false);

  const buttonStyle = useMemo(() => {
    return {
      alignSelf: "center",
      padding: "2px",
      borderRadius: "8px",
      boxShadow: "0 1px 2px 0 rgba(16, 24, 40, 0.05)",
      border: "solid 1px #40ad9f",
      backgroundColor: "#40ad9f",
    };
  }, []);

  const handleUpdateInputs = (e) => {
    setError("");
    setEmail(e.target.value);
  };


  const sendEmail = useCallback(
    async () => {
      await resetService.requestResetPassword(email);
    },
    [email],
  )


  const onSubmit = async () => {
    try {
      if (completed) {
        navigate("/");
        return;
      }
      if (Utils.validateEmail(email)) {
        await sendEmail();
        setCompleted(true);
      } else {
        setError("Email is incorrect");
      }
    } catch (ex) {
      setError("Reset Password Failed");
    }
  };

  return (
    <div className={css.wrapper}>
      <div className={css.logoWrapper}>
        <img src={TuhoonLogo} alt="logo" className={css.logo} />
        <span>{i18next.t("labels.business")}</span>
      </div>
      <div className={css.title}>{completed ? i18next.t("labels.check_your_email") : i18next.t("labels.forget_password")}</div>
      <div className={css.subtitle}>
        {completed ? i18next.t("paragraphs.check_your_email") : i18next.t("paragraphs.forget_password")}
      </div>
      <form className={css.form}>
        {completed ?
          <div className={css.competedContainer}>
            <div className={css.subtitle}>
              {i18next.t("paragraphs.spam_email")}
            </div>
            <div className={css.link} onClick={sendEmail}>
              {i18next.t("paragraphs.send_again")}
            </div>
          </div>
          :
          <div className={css.inputWrapper}>
            <label>{i18next.t("labels.email")}</label>
            <input
              type="email"
              name="email"
              placeholder={i18next.t("labels.email_placeholder")}
              onChange={handleUpdateInputs}
            />
          </div>}
        <Button
          onPress={onSubmit}
          title={completed ? i18next.t("buttons.back_to_signin") : i18next.t("labels.reset_password")}
          style={buttonStyle}
        />
        {error && <div className={css.error}>{error}</div>}
      </form>
    </div>
  );
}
