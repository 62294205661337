import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

import appReducer from "./slices/appSlice";
import usersReducer from "./slices/usersSlice";
import accountReducer from "./slices/accountSlice";
import resourcesReducer from "./slices/resourcesSlice";
import teamMembersReducer from "./slices/teamMembersSlice";
import dashboardReducer from "./slices/dashboardSlice";
import orginizationSlice from "./slices/orginizationSlice";

const appPersistConfig = {
  key: "app",
  storage,
  whitelist: ["activeItem"],
};

const accountPersistConfig = {
  key: "app",
  storage,
  whitelist: ["accessToken"],
};

export const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  users: usersReducer,
  teamMembers: teamMembersReducer,
  account: persistReducer(accountPersistConfig, accountReducer),
  resources: resourcesReducer,
  dashboard: dashboardReducer,
  orginization: orginizationSlice,
});
