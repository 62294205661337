import { store } from "redux/store";
import { API_URL } from "common/constants";
import { setAccountAccessToken } from "redux/slices/accountSlice";
import AppLogic from "AppLogic";

const sendInvite = ({ email, roles }) => {
  return AppLogic.getAxios().post(
    API_URL + "invitations",
    {
      email,
      roles,
    }
  );
};

const getInvitationDetails = (token) => {
  return AppLogic.getAxios().get(API_URL + `invitations?token=${token}`).then((response) => {
    return response.data;
  });
};

const setUserPassword = (data) => {
  return AppLogic.getAxios()
    .post(API_URL + "invitations/create-user", {
      email: data.email,
      password: data.password,
      token: data.token,
    })
    .then((response) => {
      if (response.data.accessToken) {
        store.dispatch(setAccountAccessToken(response.data?.accessToken));
      }
      return response.data;
    });
};

const invitationService = {
  sendInvite,
  getInvitationDetails,
  setUserPassword,
};

export default invitationService;
