import i18next from "i18next";
import React, { useMemo, useState } from "react";
import { Button, PageBody, PageHeader } from "components";
import "../support/support.scss";
import Tabs from "components/tabs";
import GeneralScreen from "./GeneralScreen";
import ProfileScreen from "./ProfileScreen";
import TeamScreen from "./TeamScreen";

export default function SettingPage() {
  const [active, setActive] = useState("generalScreen");

  const content = useMemo(
    () => ({
      generalScreen: <GeneralScreen />,
      profileScreen: <ProfileScreen />,
      teamScreen: <TeamScreen />,
    }),
    []
  );

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <PageHeader title={i18next.t("labels.settings")} borderless />
      <Tabs active={active} onChange={(active) => setActive(active)}>
        <div key="generalScreen">{i18next.t("settings.tabs.general")}</div>
        <div key="profileScreen">{i18next.t("settings.tabs.profile")}</div>
        <div key="teamScreen">{i18next.t("settings.tabs.team")}</div>
      </Tabs>
      <PageBody padding={"32px"}>{content[active]}</PageBody>
    </div>
  );
}
