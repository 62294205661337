import i18next from "i18next";
import React, { useCallback, useMemo, useState } from "react";
import { Button } from "components";
import css from "./login.module.scss";
import TuhoonLogo from "../../resources/images/Logo-colored.svg";
import { useDispatch, useSelector } from "react-redux";
import { loginAccount, selectAccountError } from "redux/slices/accountSlice";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({});
  const error = useSelector(selectAccountError)

  const buttonStyle = useMemo(() => {
    return {
      alignSelf: "center",
      marginTop: "8px",
      padding: "10px 18px",
      borderRadius: "8px",
      boxShadow: "0 1px 2px 0 rgba(16, 24, 40, 0.05)",
      border: "solid 1px #40ad9f",
      backgroundColor: "#40ad9f",
    };
  }, []);

  const handleUpdateInputs = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    dispatch(loginAccount(loginData));
  };

  const navigate = useNavigate();

  const goToForgetPassword = useCallback(
    () => {
      navigate("forget-password");
    },
    [navigate],
  );

  return (
    <div className={css.wrapper}>
      <div className={css.logoWrapper}>
        <img src={TuhoonLogo} alt="logo" className={css.logo} />
        <span>{i18next.t("labels.business")}</span>
      </div>
      <div className={css.title}>{i18next.t("labels.login_to_account")}</div>
      <div className={css.subtitle}>
        {i18next.t("labels.welcome_back_login")}
      </div>
      <form className={css.form}>
        <div className={css.inputWrapper}>
          <label>{i18next.t("labels.email")}</label>
          <input
            type="email"
            name="email"
            placeholder={i18next.t("labels.email_placeholder")}
            onChange={handleUpdateInputs}
          />
        </div>
        <div className={css.inputWrapper}>
          <label>{i18next.t("labels.password")}</label>
          <input
            type="password"
            name="password"
            placeholder="••••••••"
            onChange={handleUpdateInputs}
          />
        </div>
        <div className={css.forgetPass} onClick={goToForgetPassword}>
          {i18next.t("labels.forgget_password")}
        </div>
        <Button
          onPress={onSubmit}
          title={i18next.t("labels.sign_in")}
          style={buttonStyle}
        />
        {error&&<div className={css.error}>Failed To Login</div>}
      </form>
    </div>
  );
}
