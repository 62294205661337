import i18next from 'i18next'
import React from 'react'
import { PageBody, PageHeader, UsersTable } from 'components'

export default function UsersPage() {
  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <PageHeader title={i18next.t("labels.users")} />
      <PageBody padding={'32px'}>
          <UsersTable />
      </PageBody>
    </div>
  )
}
