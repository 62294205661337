import AnimatedCounter from "components/animatedCounter";
import React from "react";
import ProgressBar from "../progressBar";
import "./chart.scss";
import {
  exportAsImage,
  exportButtonDisplayBlock,
  exportButtonDisplayNone,
  chartOptionsDisplayBlock,
  chartOptionsDisplayNone,
} from "utils/exportAsImage";
import resourcesImage from "resources/icons/resources.svg";
import i18next from "i18next";

export default function StreamTitlesChart({ titles = [] }) {
  const exportRef = React.useRef();
  return (
    <div className="block">
      <div className="parent">
        <div className="chart" ref={exportRef}>
          <div className="header">
            <div className="title justify-title width-98">
              <div>{i18next.t("labels.titles_being_streamed")}</div>
              <img
                className="export-btn export-btn-black"
                alt="tuhoon"
                src={resourcesImage}
                onClick={async () => {
                  exportButtonDisplayNone();
                  chartOptionsDisplayNone();
                  await exportAsImage(
                    exportRef.current,
                    i18next.t("labels.titles_being_streamed")
                  );
                  exportButtonDisplayBlock();
                  chartOptionsDisplayBlock();
                }}
              />
            </div>
            <div className="days">{i18next.t("labels.last_7_days")}</div>
          </div>
          <div className="body">
            {titles?.length ? (
              <>
                {titles?.map((item, index) => {
                  return (
                    <div className="item" key={`${index}`}>
                      <div className="title">{item?.title}</div>
                      <ProgressBar
                        width={282}
                        height={8}
                        progress={item?.percentage}
                        backgroundColor="#D3E7F3"
                        progressColor="#58A6D2"
                      />
                      <div className="number">
                        <AnimatedCounter finish={item?.total} />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="m-auto">
                <p>{i18next.t("labels.no_data_available")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
