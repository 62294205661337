export default class Utils {
  static numberWithCommas(x) {
    return (x || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  static toDateString(dateStr) {
    try {
      const date = new Date(dateStr);
      return `${date.getDate()}`.padStart(2, 0) + "/" + `${date.getMonth()+1}`.padStart(2, 0) + "/" + `${date.getFullYear()}`;
    } catch (error) {
    }
    return "";
  }
  static capitalizeFirstLetter(str) {
    if (!str || str?.length == 0) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  static validateEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
  }
  static shuffle(array = []) {
    array = [...array];
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }
}