import React, { useCallback } from "react";
import css from "./tableRecord.module.scss";
import TrashSVG from "../../resources/icons/trash.svg";
import EditSVG from "../../resources/icons/edit.svg";
import i18next from "i18next";
import Utils from "common/utils";

export default function TableRecord({
  firstName,
  familyName,
  status,
  onDelete,
  firsttime,
  username,
  roles,
  email,
  onEdit,
  disabled
}) {
  const name = firstName ? firstName + " " + familyName : email;

  const onDeleteClick = useCallback(() => {
    if (disabled) return;
    onDelete({ email });
  }, [disabled, email, onDelete]);

  const onEditClick = useCallback(() => {
    if (disabled) return;
    onEdit({ email, roles });
  }, [disabled, email, onEdit, roles]);

  return (
    <div
      className={[
        css["table-record"],
        firsttime ? css.firsttime : css.secondtime,
      ].join(" ")}
    >
      <div className={css.email}>
        <div className={css.name}>{name}</div>
        <div>{username}</div>
      </div>
      <div className={css.status}>
        <div className={[css.text].join(" ")}>
          {i18next.t(`roles.${roles[0]}`)}
        </div>
      </div>
      <div className={css.status}>
        <div className={[css.text, css[status.toLowerCase()]].join(" ")}>
          {Utils.capitalizeFirstLetter(status)}
        </div>
      </div>
      <div className={[css.delete, disabled ? css.disabled : ''].join(" ")} onClick={onEditClick}>
        <img src={EditSVG} alt="edit" />
      </div>
      <div className={[css.delete, disabled ? css.disabled : ''].join(" ")}onClick={onDeleteClick}>
        <img src={TrashSVG} alt="delete" />
      </div>
    </div>
  );
}
