import React, { useCallback } from 'react'
import './account.scss'
import LogoutImage from '../../resources/icons/goto.svg'
import { useSelector } from 'react-redux'
import { selectAccountEmail, selectAccountName } from 'redux/slices/accountSlice'


export default function Account() {
  const username = useSelector(selectAccountName);
  const email = useSelector(selectAccountEmail);

  const logout = useCallback(
    () => {
      //Todo ali.m enhnace the logic.
      localStorage.clear();
      window.location.reload();
    },
    [],
  )
  

  return (
    <div className='account'>
      <div className='textContainer'>
        <div className='name'>{username}</div>
        <div className='email'>{email}</div>
      </div>
      <div className='imageContainer' onClick={logout}>
        <img src={LogoutImage} className='image'></img>
      </div>
    </div>
  )
}
