import React, { useRef } from "react";
import "./pageBody.scss";
export default function PageBody({ children, padding }) {
  return (
    <div className="page-body" style={{ padding }}>
      <div className="inner-body" id="inner-body">
        {children}
      </div>
    </div>
  );
}
