import AppLogic from "AppLogic";
import { API_URL } from "common/constants";

const getMoods = async (targetCountry, moodChartOption) => {
  return await AppLogic.getAxios().get(API_URL + `analytics/moods?targetCountry=${targetCountry}&option=${moodChartOption}`);
};

const getDailyActivity = async (targetCountry, activityChartOption) => {
  return await AppLogic.getAxios().get(API_URL + `analytics/daily-activity?targetCountry=${targetCountry}&option=${activityChartOption}`);
};

const getHourlyActivity = async (targetCountry) => {
  return await AppLogic.getAxios().get(API_URL + `analytics/hourly-activity?targetCountry=${targetCountry}`);
};

const getMinutesPerListener = async (targetCountry, minutesChartOption) => {
  return await AppLogic.getAxios().get(API_URL + `analytics/minPerListener?targetCountry=${targetCountry}&option=${minutesChartOption}`);
};

const getStreamedTitles = async (targetCountry) => {
  return await AppLogic.getAxios().get(API_URL + `analytics/streamedTitles?targetCountry=${targetCountry}`);
};

const getObjectives = async (targetCountry) => {
  return await AppLogic.getAxios().get(API_URL + `organization-profile/objectives?targetCountry=${targetCountry}`);
};

const dashboardServices = {
  getMoods,
  getMinutesPerListener,
  getStreamedTitles,
  getObjectives,
  getDailyActivity,
  getHourlyActivity
}

export default dashboardServices;
