import i18next from "i18next";
import React, { useRef } from "react";
import Utils from "common/utils";
import ProgressBar from "../progressBar";
import "./cards.scss";
import AnimatedCounter from "components/animatedCounter";
import {
  addShadow,
  exportAsImage,
  exportButtonDisplayBlock,
  exportButtonDisplayNone,
  chartOptionsDisplayBlock,
  chartOptionsDisplayNone,
  removeShadow,
} from "utils/exportAsImage";
import resourcesImage from "resources/icons/resources.svg";

export default function ActiveUsersCard({
  allUsers,
  activeUsers,
  expiredUsers,
  remainingSubscriptions,
}) {
  const exportRef = useRef();
  return (
    <div className="block">
      <div className="card blue" ref={exportRef}>
        <div className="title justify-title">
          <p className="m-0">{i18next.t("labels.active_subscriptions")}</p>
          <img
            className="export-btn"
            alt="tuhoon"
            src={resourcesImage}
            onClick={async () => {
              exportButtonDisplayNone();
              chartOptionsDisplayNone();
              removeShadow();
              await exportAsImage(
                exportRef.current,
                i18next.t("labels.active_subscriptions")
              );
              addShadow();
              exportButtonDisplayBlock();
              chartOptionsDisplayBlock();
            }}
          />
        </div>
        <div className="numbers">
          <AnimatedCounter finish={activeUsers} useComma />
        </div>
        <div className="progress">
          <ProgressBar
            width={290}
            height={8}
            progress={activeUsers / expiredUsers}
          />
        </div>
        <div className="title justify-title" style={{ paddingTop: "0px" }}>
          <p className="m-0">{i18next.t("labels.remaining_subscriptions")}</p>
        </div>
        <div className="numbers">
          <AnimatedCounter finish={remainingSubscriptions} useComma />
        </div>
      </div>
    </div>
  );
}
