import React from "react";
import { NAVIGATION_ITEM } from "../../common";
import Account from "../account";
import NavigationContainers from "../navigationContainer";
import "./sidebar.scss";

export default function Sidebar() {
  return (
    <div className="sidebar">
      <div className="logo" />
      <div className="topContainer">
        <NavigationContainers
          items={[NAVIGATION_ITEM.HOME, NAVIGATION_ITEM.USERS]}
        />
      </div>
      <NavigationContainers
        items={[
          // NAVIGATION_ITEM.RESOURCES,
          NAVIGATION_ITEM.SUPPORT,
          NAVIGATION_ITEM.SETTINGS,
        ]}
      />

      <div className="splitter" />

      <div className="bottomContainer">
        <Account />
      </div>
    </div>
  );
}
