import AppLogic from "AppLogic";

const getOrginizationDetails = (targetCountry) => {
  return AppLogic.getAxios().get(`organization-profile/info?targetCountry=${targetCountry}`).then((response) => {
    return response.data;
  });
};

const getTeamMembers = ({ page = 0, size = 0 }) => {
  return AppLogic.getAxios()
    .get(`organization-profile/get-members?page=${page}&size=${size}`)
    .then((response) => {
      return response.data;
    });
};

const orginizationService = {
  getOrginizationDetails,
  getTeamMembers,
};

export default orginizationService;
