import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import orginizationService from "services/orginization-profile.service";
import { FETCH_STATUS, ROLES } from "../../common";
import authService from "../../services/auth.service";

// #region Initial State
const initialState = {
  error: null,
  status: FETCH_STATUS.IDLE,
  info: null,
};
// #endregion

// #region Thunk Actions

export const getOrginizationinfo = createAsyncThunk(
  "orginization/getOrginizationinfo",
  async (targetCountry) => {
    try {
      const orginizationInfo = await orginizationService.getOrginizationDetails(
        targetCountry
      );
      return {
        info: orginizationInfo,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log("message ==>", error, message);
    }
  }
);

// #endregion

// region Main Reducer
export const orginizationSlice = createSlice({
  name: "orginization",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOrginizationinfo.pending, (state) => {
        state.status = FETCH_STATUS.LOADING;
        state.error = null;
      })
      .addCase(getOrginizationinfo.fulfilled, (state, action) => {
        return { ...state, ...action.payload, status: FETCH_STATUS.SUCCESS };
      })
      .addCase(getOrginizationinfo.rejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
        state.error = action.error.message;
      });
  },
});
// #endregion

// #region selectors
export const selectOrgnizationName = (state) => {
  return state.orginization?.info?.name;
};

export const selectIsLoadingData = (state) =>
  state.orginization.status == FETCH_STATUS.IDLE ||
  state.orginization.status == FETCH_STATUS.LOADING;

export const selectOrginizationDetails = (state) => state.orginization.info;

export const selectActiveUsers = (state) => state.orginization.info?.active;
export const selectExpiredUsers = (state) => state.orginization.info?.expired;
export const selectTotalUsers = (state) => state.orginization.info?.total;
export const selectRemainingSubscriptions = (state) =>
  state.orginization.info?.remainingSubscriptions;
export const selectInScopeCountries = (state) =>
  state.orginization.info?.inScopeCountries;

export const selectPlanExpiry = (state) => state.orginization.info?.expiry;

export default orginizationSlice.reducer;
