import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FETCH_STATUS } from "common";
import dashboardServices from "services/dashboard.service";
import i18next from "i18next";

// #region Initial State
const initialState = {
  titles: [],
  minPerListeners: [],
  moods: [],
  dailyActivity: [],
  hourlylyActivity: [],
  objectives: [],
  lastFetchTime: null,
  targetCountry: 'all',
  status: FETCH_STATUS.IDLE,
  error: null,
  minPerListenersChartOption: "Default",
  activityChartOption: "Default",
  moodChartOption: "Default"
};
// #endregion

// #region Thunk Actions
export const fetchDashboardTitles = createAsyncThunk("dashboard/titles", async (targetCountry) => {
  try {
    const { data } = await dashboardServices.getStreamedTitles(targetCountry);
    let titles = data?.data || [];
    let total = 0;
    titles?.map((item) => total += item.total);
    titles = titles?.map((item) => ({ ...item, percentage: item.total / total }));
    return {
      titles
    }
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
}
);
export const fetchDashboardMinPerListeners = createAsyncThunk("dashboard/minPerListeners", async (props = {}, { getState }) => {
  try {
    let { targetCountry = null, minutesChartOption = null } = props;
    const fetchParams = getState()?.dashboard?.fetchParams;
    targetCountry = targetCountry ?? (fetchParams?.targetCountry || 'all');
    minutesChartOption = minutesChartOption ??  (fetchParams?.minutesChartOption || i18next.t("labels.charts.options.default"));

    const { data } = await dashboardServices.getMinutesPerListener(targetCountry, minutesChartOption);
    let minPerListeners = data?.data || [];
    minPerListeners = minPerListeners.reverse();
    let dateFormat = { month: 'short', day: 'numeric' };
    if(minutesChartOption == i18next.t("labels.charts.options.yearly")){
      dateFormat = { month: 'short' };
    }

    minPerListeners = minPerListeners.map((item) => ({ ...item, date: new Date(item.date).toLocaleString('default', dateFormat) }))

    return {
      minPerListeners
    }
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
}
);
export const fetchDashboardMoods = createAsyncThunk("dashboard/moods", async (props = {}, { getState }) => {
  try {
    let { targetCountry = null, moodChartOption = null } = props;
    const fetchParams = getState()?.dashboard?.fetchParams;
    targetCountry = targetCountry ?? (fetchParams?.targetCountry || 'all');
    moodChartOption = moodChartOption ??  (fetchParams?.moodChartOption || i18next.t("labels.charts.options.default"));

    const { data } = await dashboardServices.getMoods(targetCountry, moodChartOption);
    let moods = data?.data || [];
    moods = moods.reverse();
    let dateFormat = { month: 'short', day: 'numeric' };
    if(moodChartOption == i18next.t("labels.charts.options.yearly")){
      dateFormat = { month: 'short' };
    }
    moods = moods.map((item) => ({ ...item, date: new Date(item.date).toLocaleString('default', dateFormat) }))

    return {
      moods
    }
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
}
);
export const fetchDashboardDailyActivity = createAsyncThunk("dashboard/daily-activity", async (props = {}, { getState }) => {
  try {
    let { targetCountry = null, activityChartOption = null } = props;
    const fetchParams = getState()?.dashboard?.fetchParams;
    targetCountry = targetCountry ?? (fetchParams?.targetCountry || 'all');
    activityChartOption = activityChartOption ??  (fetchParams?.activityChartOption || i18next.t("labels.charts.options.default"));

    const { data } = await dashboardServices.getDailyActivity(targetCountry, activityChartOption);
    let dailyActivity = data?.data || [];
    dailyActivity = dailyActivity.reverse();
    let dateFormat = { month: 'short', day: 'numeric' };
    if(activityChartOption == i18next.t("labels.charts.options.yearly")){
      dateFormat = { month: 'short' };
    }
    dailyActivity = dailyActivity.map((item) => ({ ...item, date: new Date(item.date).toLocaleString('default', dateFormat) }))

    return {
      dailyActivity
    }
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
}
);
export const fetchDashboardHourlyActivity = createAsyncThunk("dashboard/hourly-activity", async (targetCountry) => {
  try {
    const { data } = await dashboardServices.getHourlyActivity(targetCountry);
    let hourlyActivity = data?.data || [];
    hourlyActivity = hourlyActivity.map((item) => ({ ...item, date: new Date(item.date).toLocaleString('default', { month: 'short', day: 'numeric' }) }))

    return {
      hourlyActivity
    }
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
}
);
export const fetchDashboardObjectives = createAsyncThunk("dashboard/objectives", async (targetCountry) => {
  try {
    const { data } = await dashboardServices.getObjectives(targetCountry);
    let objectives = data?.objectives || [];
    objectives = objectives.map(item => ({ ...item, value: Math.round(Number((item?.percentage || 0).replace("%", "")) * 10) / 10 }));
    if (objectives.length > 1) {
    }
    return {
      objectives
    }
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
}
);
// #endregion

// region Main Reducer
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardTargetCountry: (state, action) => {
      const targetCountry = action.payload;
      return {
        ...state,
        targetCountry,
      };
    },
    setDashboardMinutesChartOption: (state, action) => {
      state.minPerListenersChartOption = action.payload;
    },
    setDashboardActivityChartOption: (state, action) => {
      state.activityChartOption = action.payload;
    },
    setDashboardMoodChartOption: (state, action) => {
      state.moodChartOption = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDashboardTitles.pending, (state) => {
        state.status = FETCH_STATUS.LOADING;
        state.error = null;
      })
      .addCase(fetchDashboardTitles.fulfilled, (state, action) => {
        const { titles } = (action.payload || {});
        if (titles) state.titles = titles;
        state.status = FETCH_STATUS.SUCCESS
        state.lastFetchTime = Date.now();
      })
      .addCase(fetchDashboardTitles.rejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchDashboardMinPerListeners.pending, (state) => {
        state.status = FETCH_STATUS.LOADING;
        state.error = null;
      })
      .addCase(fetchDashboardMinPerListeners.fulfilled, (state, action) => {
        const { minPerListeners } = (action.payload || {});
        if (minPerListeners) state.minPerListeners = minPerListeners;
        state.status = FETCH_STATUS.SUCCESS
        state.lastFetchTime = Date.now();
      })
      .addCase(fetchDashboardMinPerListeners.rejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchDashboardMoods.pending, (state) => {
        state.status = FETCH_STATUS.LOADING;
        state.error = null;
      })
      .addCase(fetchDashboardMoods.fulfilled, (state, action) => {
        const { moods } = (action.payload || {});
        if (moods) state.moods = moods;
        state.status = FETCH_STATUS.SUCCESS
        state.lastFetchTime = Date.now();
      })
      .addCase(fetchDashboardMoods.rejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchDashboardDailyActivity.pending, (state) => {
        state.status = FETCH_STATUS.LOADING;
        state.error = null;
      })
      .addCase(fetchDashboardDailyActivity.fulfilled, (state, action) => {
        const { dailyActivity } = (action.payload || {});
        if (dailyActivity) state.dailyActivity = dailyActivity;
        state.status = FETCH_STATUS.SUCCESS
        state.lastFetchTime = Date.now();
      })
      .addCase(fetchDashboardDailyActivity.rejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchDashboardHourlyActivity.pending, (state) => {
        state.status = FETCH_STATUS.LOADING;
        state.error = null;
      })
      .addCase(fetchDashboardHourlyActivity.fulfilled, (state, action) => {
        const { hourlyActivity } = (action.payload || {});
        if (hourlyActivity) state.hourlyActivity = hourlyActivity;
        state.status = FETCH_STATUS.SUCCESS
        state.lastFetchTime = Date.now();
      })
      .addCase(fetchDashboardHourlyActivity.rejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchDashboardObjectives.pending, (state) => {
        state.status = FETCH_STATUS.LOADING;
        state.error = null;
      })
      .addCase(fetchDashboardObjectives.fulfilled, (state, action) => {
        const { objectives } = (action.payload || {});
        if (objectives) state.objectives = objectives;
        state.status = FETCH_STATUS.SUCCESS
        state.lastFetchTime = Date.now();
      })
      .addCase(fetchDashboardObjectives.rejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
        state.error = action.error.message;
      })
  }
});
// #endregion

// #region actions
export const {
  setDashboardTargetCountry,
  setDashboardMinutesChartOption,
  setDashboardActivityChartOption,
  setDashboardMoodChartOption
} = dashboardSlice.actions;
// #endregion

// #region selectors
export const selectDashboardTitles = (state) => {
  return state.dashboard.titles;
};
export const selectDashboardMinPerListener = (state) => {
  return state.dashboard.minPerListeners;
};
export const selectDashboardMinutesChartOption = (state) => {
  return state.dashboard.minPerListenersChartOption;
};
export const selectDashboardActivityChartOption = (state) => {
  return state.dashboard.activityChartOption;
};
export const selectDashboardMoodChartOption = (state) => {
  return state.dashboard.moodChartOption;
};
export const selectDashboardMoods = (state) => {
  return state.dashboard.moods;
};
export const selectDashboardDailyActivity = (state) => {
  return state.dashboard.dailyActivity;
};
export const selectDashboardHourlyActivity = (state) => {
  return state.dashboard.hourlyActivity;
};
export const selectDashboardObjectives = (state) => {
  return state.dashboard.objectives;
};
export const selectDashboardLastFetchTime = (state) => {
  return state.dashboard.lastFetchTime;
}
export const selectDashboardTargetCountry = (state) => {
  return state.dashboard.targetCountry;
}
// #endregion

export default dashboardSlice.reducer;
