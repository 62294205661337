import React, { useEffect, useRef, useState } from "react";
import MoodsTable from "../moodTable";
import "./chart.scss";
import {
  exportAsImage,
  removeHideShowClasses,
  appendHideShowClasses,
  addMoodColumnMargin,
  removeMoodColumnMargin,
  exportButtonDisplayBlock,
  exportButtonDisplayNone,
  chartOptionsDisplayBlock,
  chartOptionsDisplayNone,
} from "../../utils/exportAsImage";
import i18next from "i18next";
import resourcesImage from "resources/icons/resources.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectDashboardMoodChartOption, setDashboardMoodChartOption } from "redux/slices/dashboardSlice";
import { CHART_OPTIONS } from "common";

export default function MoodChart({ moods }) {
  const exportRef = useRef();
  const [items, setItems] = useState([]);
  const [titles, setTitles] = useState([]);

  const moodChartOption = useSelector(selectDashboardMoodChartOption);

  const dispatch = useDispatch();
  
  useEffect(() => {
    if (moods?.length) {
      setTitles(moods.map((item) => item.date));
      setItems(moods.map((item) => [item.happy, item.normal, item.sad]));
    }
  }, [moods, setTitles, setItems]);
  return (
    <div className="block w-100">
      <div className="parent">
        <div className="chart fit" ref={exportRef}>
          <div className="header">
            <div className="title justify-title width-98">
              <div>{i18next.t("labels.company_happiness_scale")}</div>
              <div>
                <div className="button-container chart-options-container">
                  {CHART_OPTIONS.map(({ key, value, label }) => (
                      <button
                        key={key + value}
                        onClick={() => dispatch(setDashboardMoodChartOption(value))}
                        disabled={value === moodChartOption}
                      >
                        {label}
                      </button>
                    ))}
                </div>
                <img
                  className="export-btn export-btn-black"
                  alt="tuhoon"
                  src={resourcesImage}
                  onClick={async () => {
                    exportButtonDisplayNone();
                    chartOptionsDisplayNone();
                    removeHideShowClasses(exportRef.current);
                    addMoodColumnMargin(exportRef.current);
                    await exportAsImage(
                      exportRef.current,
                      i18next.t("labels.company_happiness_scale")
                    );
                    appendHideShowClasses(exportRef.current);
                    removeMoodColumnMargin(exportRef.current);
                    exportButtonDisplayBlock();
                    chartOptionsDisplayBlock();
                  }}
                />
              </div>
            </div>
            {
              moodChartOption == i18next.t("labels.charts.options.default") ? 
                (<div className="days">{i18next.t("labels.last_7_days")}</div>) :
                (<div className="days">{i18next.t("labels.current_year")}</div>)
            }
          </div>
          <div className="body pt-1 center">
            {moods?.length &&
            items?.length &&
            titles.length &&
            !Object.values(items).every((array) =>
              array?.every((element) => element === 0)
            ) ? (
              <>
                <div className="d-flex-start">
                  <p className="daily-unit-label">(users)</p>
                </div>
                <MoodsTable
                  items={items}
                  width={900}
                  height={500}
                  titles={titles}
                />
              </>
            ) : (
              <div className="m-auto">
                <p>{i18next.t("labels.no_data_available")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
