import React, { useMemo } from "react";
import "./button.scss";

export default function Button({
  title,
  onPress,
  style,
  disabled = false,
  //disabledStyle,
  className,
}) {
  return (
    <div
      className={`button ${className ? className : ""} ${disabled ? 'disabled' : ''}`}
      onClick={disabled ? () => {} : onPress}
      style={disabled ? { opacity: 0.6 } : style}
    >
      <div className="title">{title}</div>
    </div>
  );
}
