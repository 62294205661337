import i18next from "i18next";
import React, { useEffect, useRef, useState } from "react";
import "./cards.scss";
import HappySVG from "../../resources/icons/happy.svg";
import NormalSVG from "../../resources/icons/normal.svg";
import SadSVG from "../../resources/icons/sad.svg";
import AnimatedCounter from "components/animatedCounter";
import {
  addShadow,
  exportAsImage,
  exportButtonDisplayBlock,
  exportButtonDisplayNone,
  chartOptionsDisplayBlock,
  chartOptionsDisplayNone,
  removeShadow,
} from "utils/exportAsImage";
import resourcesImage from "resources/icons/resources.svg";

export default function FeelingsCard({ happy, normal, sad }) {
  const exportRef = useRef();
  const [color, setColor] = useState("green");
  const allZeros = happy == 0 && normal == 0 && sad == 0;
  useEffect(() => {
    if (happy != 0 || normal != 0 || sad != 0) {
      if (happy >= sad && happy >= normal) setColor("green");
      else if (normal >= happy && normal >= sad) setColor("blue");
      else setColor("red");
    }
  }, [happy, normal, sad]);

  return (
    <div className="block">
      <div className={`card ${color}`} ref={exportRef}>
        <div className="title justify-title">
          <p className="m-0">{i18next.t("labels.team_feeling")}</p>
          <img
            className="export-btn"
            alt="tuhoon"
            src={resourcesImage}
            onClick={async () => {
              exportButtonDisplayNone();
              chartOptionsDisplayNone();
              removeShadow();
              await exportAsImage(
                exportRef.current,
                i18next.t("labels.team_feeling")
              );
              addShadow();
              exportButtonDisplayBlock();
              chartOptionsDisplayBlock();
            }}
          />
        </div>
        <div className="charts">
          <div className="row">
            <img src={HappySVG} alt="tuhoon" />
            <div>{i18next.t("labels.happy")}</div>
            <div className="percentage">
              {allZeros ? (
                "--"
              ) : (
                <>
                  <AnimatedCounter finish={happy} />%
                </>
              )}
            </div>
          </div>
          <div className="row">
            <img src={NormalSVG} alt="tuhoon" />
            <div>{i18next.t("labels.normal")}</div>
            <div className="percentage">
              {allZeros ? (
                "--"
              ) : (
                <>
                  <AnimatedCounter finish={normal} />%
                </>
              )}
            </div>
          </div>
          <div className="row">
            <img src={SadSVG} alt="tuhoon" />
            <div>{i18next.t("labels.sad")}</div>
            <div className="percentage">
              {allZeros ? (
                "--"
              ) : (
                <>
                  <AnimatedCounter finish={sad} />%
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
