import React, { memo } from 'react'
import NavigationItem, { NAVIGATION_ITEM } from '../navigationItem'
import './navigationContainer.scss'

function NavigationContainer({ items }) {
  return (
    <div className='navigationContainer'>
      {items.map((item, index) => <NavigationItem type={item} key={item + index} />)}
    </div>
  )
}

export default memo(NavigationContainer);
