import i18next from "i18next";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import MailSVG from "resources/icons/mail.svg";
import TrashSVG from "resources/icons/trash.svg";
import PlusSVG from "resources/icons/plus.svg";
import FileUploadSVG from "resources/icons/fileupload.svg";
import "./userOnboarding.scss";
import { Button } from "components";
import { useDispatch } from "react-redux";
import { setAppModal } from "redux/slices/appSlice";
import { MODALS } from "common";
import usersService from "services/users.service";
import { fetchUsers } from "redux/slices/usersSlice";
import Utils from "common/utils";

const EmailInput = ({ index, email, onChange, onDelete, disabled }) => {
	const [isValid, setIsValid] = useState(true);

	const liRef = useRef();
	const divRef = useRef();

	const _onDelete = useCallback(() => {
		// if (liRef.current.nextSibling) {
		// 	liRef.current.classList.remove("show");
		// 	divRef.current.classList.remove("show");
		// }
		setIsValid(true);

		onDelete();
	}, [email, onDelete]);

	const onBlur = useCallback(() => {
		!email && onDelete();
		setIsValid(!email || Utils.validateEmail(email));
	}, [email]);

	const _onChange = useCallback(
		(event) => {
			!event.target.value && setIsValid(true);
			onChange(event);
		},
		[onChange]
	);

	useLayoutEffect(() => {
		setTimeout(() => {
			liRef.current.classList.add("show");
			divRef.current.classList.add("show");
		}, 15);
	}, [index, isValid]);

	return (
		<li
			ref={liRef}
			// className="list-container show"
			className={isValid ? "list-container" : "list-container error"}
		>
			<div
				ref={divRef}
				// className="email-input list-item show"
				className={
					isValid ? "email-input list-item" : "email-input list-item error"
				}
			>
				<img src={MailSVG} />
				<input
					onBlur={onBlur}
					value={email}
					type="email"
					onChange={_onChange}
					placeholder={i18next.t("labels.email_address")}
				/>
				<img
					className={disabled ? "icon disabled" : "icon"}
					src={TrashSVG}
					onClick={_onDelete}
				/>
			</div>
		</li>
	);
};

const DNDZone = ({ onFileCaptured }) => {
	const [fileName, setFileName] = useState("");
	const [hasFile, setHasFile] = useState(false);

	const fileInputRef = useRef();
	const containerRef = useRef();

	const onDrag = useCallback((event) => {
		event.preventDefault();

		containerRef.current.classList.add("highlight");
	}, []);

	const onDragLeave = useCallback((event) => {
		event.preventDefault();

		containerRef.current.classList.remove("highlight");
	}, []);

	const onDrop = useCallback((event) => {
		event.preventDefault();
		containerRef.current.classList.remove("highlight");

		fileInputRef.current.files = event.dataTransfer.files;
		const file = fileInputRef.current.files[0];

		setHasFile(true);
		setFileName(file.name);

		onFileCaptured(file);
	}, []);

	const onChange = useCallback((event) => {
		event.preventDefault();

		fileInputRef.current.files = event.target.files;
		const file = fileInputRef.current.files[0];

		setHasFile(true);
		setFileName(file.name);
		onFileCaptured(file);
	}, []);

	const onOpenFileExplorer = useCallback(
		() => fileInputRef.current.click(),
		[]
	);

	const onDelete = useCallback(() => {
		fileInputRef.current.files = null;

		setHasFile(false);
		setFileName("");

		onFileCaptured(null);
	}, []);

	return (
		<div
			ref={containerRef}
			onDragEnter={onDrag}
			onDragOver={onDrag}
			onDragLeave={onDragLeave}
			onDrop={onDrop}
			className="file-input"
		>
			<div className="upload-icon-wrapper">
				{!hasFile ? (
					<img
						onClick={onOpenFileExplorer}
						className="image-upload"
						src={FileUploadSVG}
					/>
				) : (
					<>
						<span>{fileName || "file has been uploaded"}</span>
						<img
							onClick={onDelete}
							className="image-upload"
							src={TrashSVG}
						/>
					</>
				)}
			</div>
			<div>
				<div
					className="click"
					onClick={onOpenFileExplorer}
				>
					{i18next.t("labels.click_to_upload")}
				</div>
				<div className="unclick">{`${i18next.t("labels.or_small")} ${i18next.t(
					"labels.drag_and_drop"
				)}`}</div>
			</div>
			<div className="unclick">{`XLSX`}</div>
			<input
				onChange={onChange}
				ref={fileInputRef}
				type="file"
			/>
		</div>
	);
};

export default function UsersOnboarding() {
	const [emails, setEmails] = useState([""]);
	const [isValidEmail, setIsValidEmail] = useState(true);

	const ButtonContainerRef = useRef();
	const emailsRef = useRef([""]);
	const modalContainerRef = useRef();
	const emailInputListRef = useRef();
	const fileRef = useRef();

	const dispatch = useDispatch();

	const calculateDimensionsAndPositions = useCallback(() => {
		if (!modalContainerRef.current || !emailInputListRef.current) return;

		const INITIAL_ADD_BUTTON_POSSITION = 72;
		const INITIAL_CONTAINER_HEIGHT = 464;
		const EMAIL_INPUT_PADDING = 10;
		const ADD_BUTTON_MARGIN = 12;
		const MIN_EMAIL_INPUT_NUMBER = 1;
		const MAX_EMAIL_INPUT_NUMBER = 3;
		const EMAIL_INPUT_HEIGHT =
			emailInputListRef.current.firstElementChild.clientHeight;
		const MAX_EMAIL_INPUT_LIST_HEIGHT =
			EMAIL_INPUT_HEIGHT * MAX_EMAIL_INPUT_NUMBER +
			EMAIL_INPUT_PADDING * MAX_EMAIL_INPUT_NUMBER +
			EMAIL_INPUT_HEIGHT / 2;

		const emailInpitNumber = emailsRef.current.length;
		const inset =
			(emailInpitNumber - MIN_EMAIL_INPUT_NUMBER) * EMAIL_INPUT_PADDING;
		const emailListHeight =
			(emailInpitNumber - MIN_EMAIL_INPUT_NUMBER) * EMAIL_INPUT_HEIGHT + inset;
		const height = INITIAL_CONTAINER_HEIGHT + emailListHeight;
		const top =
			INITIAL_ADD_BUTTON_POSSITION + emailListHeight + ADD_BUTTON_MARGIN;

		modalContainerRef.current.style.minHeight = `${INITIAL_CONTAINER_HEIGHT}px`;

		if (emailInpitNumber <= MAX_EMAIL_INPUT_NUMBER) {
			modalContainerRef.current.style.height = `${height}px`;
			emailInputListRef.current.nextSibling.style.top = `${top}px`;
		} else if (emailInpitNumber > MAX_EMAIL_INPUT_NUMBER) {
			emailInputListRef.current.style.maxHeight = `${MAX_EMAIL_INPUT_LIST_HEIGHT}px`;
			modalContainerRef.current.style.height = `${
				INITIAL_CONTAINER_HEIGHT +
				MAX_EMAIL_INPUT_LIST_HEIGHT -
				ADD_BUTTON_MARGIN -
				EMAIL_INPUT_HEIGHT
			}px`;
			emailInputListRef.current.nextSibling.style.top = `${
				ADD_BUTTON_MARGIN +
				MAX_EMAIL_INPUT_LIST_HEIGHT +
				MAX_EMAIL_INPUT_NUMBER * EMAIL_INPUT_PADDING
			}px`;
		}
	}, []);

	const onChange = useCallback(
		(index) => (event) => {
			emailsRef.current[index] = event.target.value;
			setEmails([...emailsRef.current]);
		},
		[]
	);

	const addNewEmailField = useCallback(() => {
		if (!Utils.validateEmail(emailsRef.current.at(-1)))
			return setIsValidEmail(!emailsRef.current.at(-1));

		setIsValidEmail(true);

		emailsRef.current = [...emailsRef.current, ""];

		calculateDimensionsAndPositions();

		setEmails([...emailsRef.current]);
	}, [setEmails, calculateDimensionsAndPositions]);

	const deleteEmail = useCallback(
		(index) => () => {
			emailsRef.current.splice(index, 1);

			if (emailsRef.current.length === 0) emailsRef.current = [""];

			setTimeout(() => calculateDimensionsAndPositions(), 15);
			setTimeout(() => setEmails([...emailsRef.current]), 20);
		},
		[setEmails, calculateDimensionsAndPositions]
	);

	const onFileCaptured = useCallback((file) => {
		fileRef.current = file;
	}, []);

	const onCancel = useCallback(() => {
		dispatch(setAppModal(MODALS.NONE));
	}, [dispatch]);

	const setDNDZoneError = useCallback((error) => {
		ButtonContainerRef.current.previousElementSibling.classList.add("error");
		ButtonContainerRef.current.previousElementSibling.setAttribute(
			"data-error",
			error
		);
	}, []);

	const onSend = useCallback(async () => {
		setDNDZoneError("");

		let isValid = isValidEmail || !!fileRef.current;

		if (!isValid)
			return setDNDZoneError(
				"Either email or a an excel file containing emails are required"
			);

		let _emails = emailsRef.current.filter((email) => email !== "");

		for (const email of _emails) {
			if (!Utils.validateEmail(email)) {
				return setDNDZoneError("Some emails entered are incorrect");
			}
		}

		const formData = new FormData();

		_emails.length && formData.append("emails", JSON.stringify(_emails));

		fileRef.current && formData.append("emailsFile", fileRef.current);

		const hasDate = formData.has("emails") || formData.has("emailsFile");

		if (!hasDate)
			return setDNDZoneError(
				"Either email or a an excel file containing emails are required"
			);

		try {
			const { error, invalidEmailRows } = await usersService.postUsersEmails(formData);
			if (error) {
				setDNDZoneError(error)
			} else {
				dispatch(fetchUsers());
				dispatch(setAppModal(MODALS.NONE));
			}
		} catch (error) {
			setDNDZoneError("something went wrong! please try again later");
		}
	}, [isValidEmail]);

	useLayoutEffect(() => {
		calculateDimensionsAndPositions();
	}, []);

	useLayoutEffect(() => {
		emailInputListRef.current.lastChild?.scrollIntoView?.({
			behavior: "smooth",
			block: "end",
			inline: "nearest",
		});
	}, [emails]);

	return (
		<div
			ref={modalContainerRef}
			className="user-onboarding"
		>
			<div className="header">
				<div className="title">{i18next.t("labels.user_onboarding")}</div>
				<div className="title-supporting">
					{i18next.t("paragraphs.user_onboarding")}
				</div>
			</div>
			<div className="body">
				<div className="title">{i18next.t("labels.email_address")}</div>
				<ul
					ref={emailInputListRef}
					className="list"
					role="list"
					aria-live="assertive"
				>
					{emails.map((email, index) => {
						return (
							<EmailInput
								index={index}
								email={email}
								onChange={onChange(index)}
								disabled={!email}
								onDelete={deleteEmail(index)}
								key={`email-input-${index}`}
							/>
						);
					})}
				</ul>
				<div className="body">
					<div
						className={isValidEmail ? "add" : "add error"}
						onClick={addNewEmailField}
					>
						<img src={PlusSVG}></img>
						<div>{i18next.t("labels.add_another")}</div>
					</div>
					<div className="or">{i18next.t("labels.or")}</div>
					<DNDZone onFileCaptured={onFileCaptured} />
					<div
						ref={ButtonContainerRef}
						className="buttons_container"
					>
						<Button
							title={"Cancel"}
							className="cancel"
							onPress={onCancel}
						/>
						<Button
							onPress={onSend}
							title={"Send"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
