import i18next from "i18next";
import React, { useCallback } from "react";
import "./table.scss";
import Arrow from "../../resources/icons/arrow-up.svg";
import TableRecord from "./tableRecord";
import TableNavigation from "./tableNavigation";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUsersAll,
  selectUsersTotalPages,
} from "../../redux/slices/usersSlice";
import { setAppModal } from "redux/slices/appSlice";
import { MODALS } from "common";
import Loader from "components/loader";
import { isAdminAccount } from "redux/slices/accountSlice";

export default function Table(props) {
  const { currentPage, setCurrentPage, isLoading, onOrder, order, sortedBy } =
    props;
  const reduxUsers = useSelector(selectUsersAll);
  const dispatch = useDispatch();
  const totalPages = useSelector(selectUsersTotalPages);
  const isAdmin = useSelector(isAdminAccount);

  const onDelete = useCallback(
    ({ email, phoneNumber }) => {
      dispatch(
        setAppModal({ modal: MODALS.USER_DELETE, data: { email, phoneNumber } })
      );
    },
    [dispatch]
  );

  const onPageChange = useCallback(
    (page) => {
      setCurrentPage(page - 1);
    },
    [setCurrentPage]
  );

  return (
    <div className="user-table">
      <div className="header">
        <div className="username">{i18next.t("labels.name")}</div>
        <div className="username">{i18next.t("labels.email")}</div>
        <div className="username">{i18next.t("labels.phone_number")}</div>
        <div className="username">{i18next.t("labels.gender")}</div>
        <div className="username">{i18next.t("labels.country")}</div>
        <div
          className={`status ${order == "asc" ? "flip" : ""}`}
          onClick={() => onOrder("created_on")}
        >
          <div>{i18next.t("labels.created_on")}</div>
          {sortedBy == "created_on" ? (
            <img className="icon" src={Arrow} alt="up" />
          ) : null}
        </div>
        <div
          className={`status ${order == "asc" ? "flip" : ""}`}
          onClick={() => onOrder("status")}
        >
          <div>{i18next.t("labels.status")}</div>
          {sortedBy == "status" ? (
            <img className="icon" src={Arrow} alt="up" />
          ) : null}
        </div>
        <div className="last" />
      </div>
      <div className="body">
        {isLoading ? (
          <Loader />
        ) : reduxUsers?.length ? (
          reduxUsers?.map((item, index) => (
            <TableRecord
              {...item}
              key={(item.email || item.phoneNumber) + item.id + index}
              disabled={!isAdmin}
              onDelete={onDelete}
            />
          ))
        ) : (
          <p className="center">{i18next.t("labels.no_record")}</p>
        )}
      </div>
      {!isLoading && (
        <div className="footer">
          <TableNavigation
            currentPage={currentPage + 1}
            onPageChange={onPageChange}
            pageCount={totalPages}
          />
        </div>
      )}
    </div>
  );
}
