import React, { useEffect, useRef, useState } from "react";
import { usePrevious } from "common/usePrevious";
import "./tabs.scss";

const transitionTime = 200;
const transitionStyle = `left ${transitionTime}ms, right ${transitionTime}ms`;

const Tabs = (props) => {
  const { children, active, onChange } = props;

  const [sizes, setSizes] = useState({});
  const mainWrapper = useRef(null);

  const els = useRef({});

  const prevChilderen = usePrevious(children);
  const prevActive = usePrevious(active);

  const getSizes = () => {
    const rootBounds = mainWrapper?.current?.getBoundingClientRect();

    const sizes = {};

    Object.keys(els.current).forEach((key) => {
      const el = els.current[key];
      const bounds = el.getBoundingClientRect();

      const left = bounds.left - rootBounds.left;
      const right = rootBounds.right - bounds.right;

      sizes[key] = { left, right };
    });
    setSizes(sizes);
    return sizes;
  };

  const getUnderlineStyle = () => {
    if (active == null || Object.keys(sizes).length === 0) {
      return { left: "0", right: "100%" };
    }

    const size = sizes[active];
    return {
      left: `${size.left}px`,
      right: `${size.right}px`,
      transition: transitionStyle,
    };
  };

  // once on render
  useEffect(() => {
    getSizes();
  }, []);

  useEffect(() => {
    if (children !== prevChilderen && active !== prevActive) getSizes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, active]);

  return (
    <div className="Tabs" ref={mainWrapper}>
      {React.Children.map(children, (child, i) => {
        let className = `Tabs__Tab`;
        if (child.key === active) {
          className = `${className} Tabs__Tab--active`;
        }
        return (
          <div
            className={className}
            onClick={() => {
              onChange(child.key);
            }}
            ref={(el) => (els.current[child.key] = el)}
          >
            {child}
          </div>
        );
      })}
      <div className="Tabs__Underline" style={getUnderlineStyle()} />
    </div>
  );
};

export default Tabs;
