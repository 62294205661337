import { createSlice } from "@reduxjs/toolkit";
import { NAVIGATION_ITEM } from "common";
import { MODALS } from "common";

// #region Initial State
const initialState = {
	activeItem: NAVIGATION_ITEM.HOME,
	activeModal: MODALS.NONE,
	activeModalData: Object.keys(MODALS).reduce((total, current) => (total[current] = '', total), {}),
	error: null,
};
// #endregion

// #region Thunk Actions
// #endregion

// region Main Reducer
export const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setAppActiveItem: (state, { payload }) => {
			state.activeItem = payload;
		},
		setAppModal: (state, { payload }) => {
			const modal = (payload && typeof payload == "string") ? payload : payload?.modal;
			const data = payload?.data || null;
			state.activeModal = modal;
			state.activeModalData[modal] = data;
		},
	}
});
// #endregion

// #region actions
export const {
	setAppActiveItem,
	setAppModal
} = appSlice.actions;
// #endregion

// #region selectors
export const selectAppActiveItem = (state) => {
	return state.app.activeItem;
};
export const selectAppModal = (state) => {
	return state.app.activeModal;
};
export const  selectAppModalData = (modal) => {
	return (state) => {
		return state.app.activeModalData[modal];
	};
}
// #endregion

export default appSlice.reducer;
