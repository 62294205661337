import i18next from "i18next";
import React, { useState, useCallback, useMemo } from "react";
import "./memberDelete.scss";
import { Button } from "components";
import { useDispatch, useSelector } from "react-redux";
import { selectAppModalData, setAppModal } from "redux/slices/appSlice";
import { MODALS } from "common";

import usersService from "services/users.service";
import { fetchMembers } from "redux/slices/teamMembersSlice";

export default function MemberDelete() {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const email =
    useSelector(selectAppModalData(MODALS.MEMBER_DELETE))?.email || "";
  const onDelete = useCallback(async () => {
    try {
      const deleteMember = await usersService.deleteMember(email);
    if (deleteMember) {
      dispatch(fetchMembers());
      dispatch(setAppModal(MODALS.NONE));
    }
    } catch (error) {
      setError("Failed to delete User")
    }
  }, [email, dispatch]);

  const onCancel = useCallback(() => {
    dispatch(setAppModal(MODALS.NONE));
  }, [dispatch]);

  const cancelStyle = useMemo(() => {
    return {
      color: "#344054",
      backgroundColor: "#ffffff",
      shadowColor: "rgba(16, 24, 40, 0.05)",
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowRadius: 2,
      shadowOpacity: 1,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#d0d5dd",
    };
  }, []);

  return (
    <div className="user-delete">
      <div className="title">{i18next.t("labels.delete_member")}</div>
      <div className="question">{i18next.t("paragraphs.delete_user")}</div>
      <div className="email">{email}</div>
      <div className="buttons_container">
        <Button title={"Cancel"} style={cancelStyle} onPress={onCancel} />
        <Button title={"Send"} onPress={onDelete} />
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
}
