import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "components";
import css from "./setPassword.module.scss";
import TuhoonLogo from "../../resources/images/Logo-colored.svg";
import invitationService from "services/invite-members.service";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveUser } from "redux/slices/accountSlice";
import resetService from "services/resetPassword.service";

export default function SetPasswordPage({isReset}) {
  const [invitationDetails, setInvitationDetails] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { search } = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!invitationDetails) {
      const params = new URLSearchParams(search);
      const token = params.get("token");
      if (token) {
        if(!isReset)
        {
          invitationService
            .getInvitationDetails(token)
            .then((data) => setInvitationDetails(data?.invitationDetails));
        } else {
          setInvitationDetails({token});
        }
      } else {
        alert("unvalid token");
        return navigate("/");
      }
    }
  }, []);

  const buttonStyle = useMemo(() => {
    return {
      alignSelf: "center",
      marginTop: "8px",
      padding: "10px 18px",
      borderRadius: "8px",
      boxShadow: "0 1px 2px 0 rgba(16, 24, 40, 0.05)",
      border: "solid 1px #40ad9f",
      backgroundColor: "#40ad9f",
    };
  }, []);

  const handleSubmit = async () => {
    if (password.length > 0 && password === confirmPassword) {
      if (isReset) {
        const resetPassword = await resetService.resetPassword({
          password,
          token: invitationDetails.token,
        });
        navigate("/");
      } else {
        const setUserPassword = await invitationService.setUserPassword({
          email: invitationDetails.email,
          token: invitationDetails.token,
          password: password,
        });
        dispatch(saveUser(setUserPassword));
      }
      return navigate("/");
    }
  };

  if (!invitationDetails && !isReset) {
    return <div>loading ...</div>;
  }
  return (
    <div className={css.wrapper}>
      <div className={css.logoWrapper}>
        <img src={TuhoonLogo} alt="logo" className={css.logo} />
        <span>{i18next.t("labels.business")}</span>
      </div>
      <div className={css.title}>{isReset ? i18next.t("labels.reset_password") : i18next.t("labels.set_password")}</div>
      <div className={css.subtitle}>
        {
        isReset ?
        i18next.t("labels.welcome_back_login")
        :
        i18next.t("labels.welcome_set_password", {
          email: invitationDetails.email,
        })}
      </div>
      <form className={css.form}>
        <div className={css.inputWrapper}>
          <label>{i18next.t("labels.new_password")}</label>
          <input
            type="password"
            name="password"
            placeholder="••••••••"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={css.inputWrapper}>
          <label>{i18next.t("labels.confirm_new_password")}</label>
          <input
            type="password"
            name="confirmPassword"
            placeholder="••••••••"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div className={css.forgetPass}>
          {i18next.t("labels.forgget_password")}
        </div>
        <Button
          title={isReset ? i18next.t("buttons.change_password") : i18next.t("labels.set_password")}
          style={buttonStyle}
          onPress={handleSubmit}
        />
      </form>
    </div>
  );
}
