import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import orginizationService from "services/orginization-profile.service";
import { FETCH_STATUS } from "../../common";

// #region Initial State
const initialState = {
  members: [],
  error: null,
  status: FETCH_STATUS.IDLE,
  pages: null,
  fetchParams: {},
};
// #endregion

// #region Thunk Actions
export const fetchMembers = createAsyncThunk(
  "teamMembet/fetchMembers",
  async (props = {}, { getState }) => {
    try {
      let {page = null, size = null} = props;
      const fetchParams = getState()?.teamMembers?.fetchParams;
      page = page ?? (fetchParams?.page || 0);
      size = size ?? (fetchParams?.size || 10);

      const teamMember = await orginizationService.getTeamMembers({ page, size });
      return {
        members: teamMember.items,
        error: null,
        totalMembers: teamMember.totalItems,
        totalPages: teamMember.totalPages,
        currentPage: teamMember.currentPage,
        fetchParams,
      };
    } catch (ex) {
      console.error(ex);
      throw (ex);
    }
  }
);
// #endregion

// region Main Reducer
export const teamMembersSlice = createSlice({
  name: "teamMembers",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMembers.pending, (state) => {
        state.status = FETCH_STATUS.LOADING;
        state.error = null;
      })
      .addCase(fetchMembers.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          pages: { [action.payload.currentPage]: action.payload.members },
          members: [...state.members, ...action.payload.members],
          status: FETCH_STATUS.SUCCESS,
        };
      })
      .addCase(fetchMembers.rejected, (state, action) => {
        state.status = FETCH_STATUS.FAILED;
        state.error = action.error.message;
      });
  },
});
// #endregion

// #region actions
// export const {} = teamMembersSlice.actions;
// #endregion

// #region selectors
export const selectMembersCount = (state) => {
  return state.teamMembers.usersCount;
};
export const selectMembersActive = (state) => {
  return state.teamMembers.activeUsers;
};
export const selectMembersAll = (state) => {
  return state.teamMembers.members;
};
export const selectMembersReady = (state) => {
  return (
    state.teamMembers.status != FETCH_STATUS.IDLE &&
    state.teamMembers.status != FETCH_STATUS.LOADING
  );
};

export const getPages = (state) => state.teamMembers.pages;
export const selectTotalPages = (state) => state.teamMembers.totalPages;
// #endregion

export default teamMembersSlice.reducer;
